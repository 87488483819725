import React, { useRef, forwardRef } from 'react';
import { MeshRefractionMaterial, Trail, useGLTF } from '@react-three/drei';
import { MeshBasicMaterial } from 'three';
import { useLoader } from '@react-three/fiber';
import { RGBELoader } from 'three-stdlib';

// Forward ref from parent to group
export const WormholeBadger = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF('/hb.glb');

  const backMat = new MeshBasicMaterial({ color: 'white' });
  const bodyMat = new MeshBasicMaterial({ color: 'grey' });
  const texture = useLoader(RGBELoader, 'https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/aerodynamics_workshop_1k.hdr')

  return (
    <group ref={ref} {...props} dispose={null}>
      <group position={[0, 0.246, 0]} rotation={[0, -1.565, 0]}>

            <mesh
            castShadow
            receiveShadow
            geometry={nodes.HoneyBadger_1.geometry}
            material = {bodyMat}
            >
            </mesh>
            <mesh
            castShadow
            receiveShadow
            geometry={nodes.HoneyBadger_2.geometry}
            material={backMat}>
            </mesh>
            <mesh
            castShadow
            receiveShadow
            geometry={nodes.HoneyBadger_3.geometry}
            material={bodyMat}
            />

      </group>
    </group>
  );
});

// Preload GLTF model
useGLTF.preload('/hb.glb');