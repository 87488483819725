import '../../../css/TextStyles.css'
import menu from '../../../assets/menu.png'
import {animated, useSpring} from 'react-spring'
import { useEffect, useState, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { HoneyBadgerModelBarebones } from '../../Models/HoneyBadgerModel'
import { AspectManager } from '../../Helpers/ClipManager'
import SidebarItems from './SidebarItems'
import arrow from '../../../assets/arrow.png'
import styled from 'styled-components'

import home from '../../../assets/homeicon.png'
import { Link } from 'react-router-dom'

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
`

export default function Sidebar(props)
{
    const [init, setInit] = useState(true)

    const [scrollHits, setHits] = useState(0);

    const duration = useRef(2000)


    const SidebarAnimation2 = useSpring({
        from: {opacity: 1, position: "fixed", height: "100vh", zIndex: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', width: "20vmax", background: "#202020", borderRight: "1px solid #ababab"},
        to: {opacity: 1, position: "fixed",height: "100vh", marginLeft: (props.docked == false) ? "0vmax":"-15vmax", zIndex: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', width: "20vmax", background: "#202020", borderRight: "1px solid #ababab"},
        config: { duration: duration.current},
        delay: 0
    })

    useEffect(() =>
    {
        //setInit(false);
    }, [init])

    function HitManage(up)
    {
        if(up)
        {
            if(scrollHits < 0) setHits(scrollHits + 1);
            return;
        }

        if(scrollHits > -10)
        {
            setHits(scrollHits - 1);
        }

    }
    
    return(
        <animated.div style = {{...SidebarAnimation2, zIndex: 1}}>
            
            <Canvas style = {{height: "10vmax", zIndex: 500, position: "absolute"}}>
                <color attach = "background" args = {["black"]} />
                <HoneyBadgerModelBarebones scale = { AspectManager(8) } rotation = {[0, 0, 0]}/>
                <pointLight intensity = {50} />
            </Canvas>

            <div style = {{position: "absolute", marginLeft: "20vmax", width: "1.5vmax", height: "2vmax", marginTop: "17.5vmax", background: "#303030", zIndex: 100,
            borderRadius: "3px", cursor: 'pointer', display: "flex",  justifyContent: 'center', alignItems: 'center', border: "1px solid #ababab"}}
            onClick = {(v) => {HitManage(true)}}
            >  
                <img src = {arrow} alt = "arrow" style = {{width: "1vmax"}} />
            </div>

            <div style = {{position: "absolute", marginLeft: "20vmax", width: "1.5vmax", height: "3vmax", marginTop: "20.5vmax", background: "#303030", zIndex: 100,
            borderRadius: "20px", cursor: 'pointer', display: "flex",  justifyContent: 'center', alignItems: 'center', border: "1px solid #ababab"}}
            onClick={() => {
                props.setDocked(!props.docked)
                if(duration.current == 2000)
                {
                    duration.current = 750
                }
                }}>

                <img src = {menu} style = {{width: "1vmax"}}/>

            </div>

            <div style = {{position: "absolute", marginLeft: "20vmax", width: "1.5vmax", height: "2vmax", marginTop: "24.7vmax", background: "#303030", zIndex: 100,
            borderRadius: "3px", cursor: 'pointer', display: "flex",  justifyContent: 'center', alignItems: 'center', border: "1px solid #ababab"}}
            onClick = {(v) => {HitManage(false)}}
            >  
                <img 
                src = {arrow} alt = "arrow" style = {{width: "1vmax", rotate: "180deg"}}/>
            </div>
            
            <SidebarItems page = {props.page} setPage = {(v) => props.setPage(v)} height = {props.height}
                setHeight = {(v) => props.setHeight(v)}
                hits = {scrollHits}
            />

            <Col style = {{marginTop: "5vmax"}}>
                <Row style = {{gap: "2vw"}}>
                    <Col style = {{alignItems: "center", justifyContent: "center"}}>
                        <Link to = "/">
                            <img src = {home} style = {{width: "5vmax"}} />
                        </Link>
                        <h3 style = {{color: "white", marginTop: "-.5vh"}} className = "inter">Home</h3>
                    </Col>

                </Row>
            </Col>

        </animated.div>
    )
}