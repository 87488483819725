import React, {  useEffect, useRef, useState, useMemo } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {  useGLTF } from "@react-three/drei";
import {  Vector3 } from "three";
import easeInOutQuad from "../Helpers/easeInOutQuad";
import { lerp } from "three/src/math/MathUtils";
import { fragmentShader, vertexShader } from "./shadershb";
import { ShaderMaterial } from "three";
import gsap from "gsap";
import { deviceType } from "react-device-detect";
import { AnimationMixer } from "three";
import { useAnimations } from "@react-three/drei";
import { LoopRepeat } from "three";
import { isMobile } from "react-device-detect";

export const HoneyBadgerModelMainUpdated = (props) => 
{

    const { nodes, animations } = useGLTF("/hbupdated.glb");
    const ref = useRef();
    const { actions, names } = useAnimations(animations, ref);

    const [cameraPos, setPos] = useState(new Vector3())
    const hasLoaded = useRef(false)
    const rot = useRef(0)
    const wait = useRef(0)
    const r = useRef()
    const r2 = useRef()
    const r3 = useRef()
    const HoverOut = useRef(null)
    const [hovered, setHovered] = useState(false);
    const elapsed = useRef(0)
    const [complete, setComplete] = useState(false)
    const [center,setCenter] = useState(null)
    const [ambientA, setAmbientA] = useState(null)
    const [hasHovered, setHasHovered] = useState(false);
    const [startPos, setStartPos] = useState(null);
    const [endPos, setEndPos] = useState(null);

    const endAnim = useRef(0)
    const stoppedWalking = useRef(false)
    const {camera} =  useThree();
    let ev;

    const shaderMatBody = useMemo(() =>
    {
       return new ShaderMaterial({
            extensions: 
            {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: {
                time: {value: 0},
                u_hovered: {value: 0.},
                u_back: {value: 0.},
                u_transitionTime: {value: 0.},
                u_opacity: {value: 0.}
            },
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            wireframe: false,
            transparent: true
        });
    }, [])

    const shaderMatBack = useMemo(() =>
    {
       return new ShaderMaterial({
            extensions: 
            {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            uniforms: {
                time: {value: 0},
                u_hovered: {value: 0.},
                u_back: {value: 1.},
                u_transitionTime: {value: 0.},
                u_opacity: {value: 0.}
            },
            fragmentShader: fragmentShader,
            vertexShader: vertexShader,
            wireframe: false,
            transparent: true
        });
    }, [])

    if(ref.current != undefined && !HoverOut.current)
    {
        HoverOut.current = gsap.fromTo(camera.position, {z: cameraPos.z}, {z: camera.position.z - .15, paused: true, duration: .2})
    }

    //set startPos and endPos for resolution
    useEffect(() =>
    {
        let res = window.innerWidth / window.innerHeight;

        if(res >= 2) 
        {
            setStartPos(40)
            setEndPos(-40)
        }
        else if(res >= 1.8)
        {
            setStartPos(35)
            setEndPos(-35)
        }
        else if(res >= 1.6)
        {
            setStartPos(35)
            setEndPos(-35)
        }
        else if(res >= 1.4)
        {
            setStartPos(33)
            setEndPos(-33)
        }
        else if(res >= 1.2)
        {
            setStartPos(28)
            setEndPos(-28)
        }
        else if(res >= 1.0)
        {
            setStartPos(15)
            setEndPos(-15)
        }
        else if(res >= .8)
        {
            setStartPos(12.5)
            setEndPos(-12.5)
        }
        else if(res >= .6)
        {
            setStartPos(11.5)
            setEndPos(-11.5)
        }
        else if(res >= .4)
        {
            setStartPos(10.5)
            setEndPos(-10.5)
        }
        else
        {
            setStartPos(9.5)
            setEndPos(-9.5)
        }

    }, [])

    useEffect(() =>
    {
        if(hasHovered !== true && ref.current && startPos)
        {
             let ambientAnimation = gsap.timeline({paused: true, repeat: -1, delay: 2})
             .to(ref.current.position, {x: startPos, duration: 6})
             .to(ref.current.rotation, {y: Math.PI / 2, duration: .2}, "<")
             .to(ref.current.position, {x: endPos, duration: 6})
             .to(ref.current.rotation, {y: -Math.PI / 2, duration: .2}, "<")
            .play();

            setAmbientA(ambientAnimation)
        }

    }, [startPos])

    useEffect(() =>
    {
        console.log(actions,names);

        if(!actions || !names) return;

        let firstAction = actions[names[0]];
        firstAction.play();
        
    }, [actions, names])


    useEffect(() =>
    {
        document.body.style.cursor = (hovered && props.phase == 0) ? 'pointer':'auto';

        if(hovered && !hasHovered)
        {
            ambientA.pause();

            let center = gsap.timeline({paused: true})
            center.to(ref.current.position, {x: 0, duration: 1}).to(ref.current.rotation, {y: Math.PI/2})
            center.play();

            if(!stoppedWalking.current)
            {
                if(!actions || !names) 
                {

                    return;
                }
                let firstAction = actions[names[0]];

                let remainingTime = firstAction.getClip().duration - firstAction.time;

                // Slow down to finish exactly at the end of the animation
                gsap.to(firstAction, {
                    timeScale: 0,
                    duration: remainingTime,
                    ease: "none",
                    onComplete: () =>
                    {
                    }
                });
                
                
                stoppedWalking.current = true;
            }

            setCenter(center)
            setHasHovered(true);
        }

        if(cameraPos !== undefined)
        {
            cameraPos.x = camera.position.x;
            cameraPos.y = camera.position.y;
            cameraPos.z = camera.position.z;
        }

        if(hovered == true)
        {
            r.current.material.uniforms.u_hovered.value = 1;
            r2.current.material.uniforms.u_hovered.value = 1;
        }
        else
        {
            r.current.material.uniforms.u_hovered.value = 0;
            r2.current.material.uniforms.u_hovered.value = 0;
        }
        

    }, [hovered])

    useFrame((_, delta) =>
    {
        if(complete) return;

        //just entering the screen
        if(hasLoaded.current === false)
        {
            if(!r.current) return;
            if(wait.current < 1)
            {
                wait.current += delta;
                return;
            }
            else if(r.current.material.uniforms.u_opacity.value < 1.)
            {
                r.current.material.uniforms.u_opacity.value = r.current.material.uniforms.u_opacity.value + delta * .35;
                r2.current.material.uniforms.u_opacity.value = r2.current.material.uniforms.u_opacity.value + delta * .35;
            }
            else
            {
                hasLoaded.current = true;
            }
        }
        //has entered teh screen, has not been clicked
        else if(r.current != undefined && props.hasTransitioned === false)
        {
            if(hovered)
            {
                r.current.material.uniforms.time.value = r.current.material.uniforms.time.value + delta;
                r2.current.material.uniforms.time.value = r2.current.material.uniforms.time.value + delta;
    
                if(r.current.material.uniforms.u_transitionTime.value < 1.)
                {
                    r.current.material.uniforms.u_transitionTime.value = r.current.material.uniforms.u_transitionTime.value + delta * .5;
                    r2.current.material.uniforms.u_transitionTime.value = r2.current.material.uniforms.u_transitionTime.value + delta * .5;
                }
            }
            else
            {
                if(r.current.material.uniforms.u_transitionTime.value > 0)
                {
                    r.current.material.uniforms.time.value = r.current.material.uniforms.time.value + delta;
                    r2.current.material.uniforms.time.value = r2.current.material.uniforms.time.value + delta;
        
                    r.current.material.uniforms.u_transitionTime.value = r.current.material.uniforms.u_transitionTime.value - delta * .5;
                    r2.current.material.uniforms.u_transitionTime.value = r2.current.material.uniforms.u_transitionTime.value - delta * .5;
                }
            }
        }

        //has been clicked, now we need to transition the badger from its active state
        if(props.phase == 1 && ref.current.scale.x > 0 && props.hasTransitioned == false)
        {

            if(rot.current < 100)
            {
                rot.current += delta * 25;
                ev = easeInOutQuad(rot.current / 100)
                ref.current.rotation.y = lerp(Math.PI / 2, Math.PI, ev);
            }
            if(rot.current >= 100)
            {

                if(endAnim.current == 0)
                {
                    let firstAction = actions[names[0]];
                    firstAction.timeScale = 4;
                    firstAction.play();
                    endAnim.current = 1;
                }

                ref.current.position.z -= delta * 20;  
            }
            if(ref.current.position.z < -20)
            {
                if(endAnim.current == 1)
                {
                    let firstAction = actions[names[0]];
                    firstAction.stop();
                }

                let deltaValue = (deviceType === "browser") ? delta * 1000.5 : delta * 3000.5;
                ref.current.scale.x -= deltaValue;
                ref.current.scale.y -= deltaValue;
                ref.current.scale.z -= deltaValue; 
            }
            if(document.body.style.cursor != 'auto') {document.body.style.cursor = 'auto'}
        }
        else if(props.hasTransitioned == false && props.phase == 1)
        {
            props.setTransitioned(true)
        }

        if(props.hasTransitioned ===  false)
        {
            elapsed.current += delta;
        }
        else
        {
            ref.current.scale.x = 0;
            ref.current.scale.y = 0;
            ref.current.scale.z = 0;

            setComplete(true);
        }
    })

    function HandleHover(e)
    {
        if(isMobile) return;
        if(props.phase == 0)
        {
            if(!r.current) return;
            if(r.current.material.uniforms.u_opacity.value < .2) return;
            if(elapsed.current < 4) return;

            e.stopPropagation();

            setHovered(true)
            props.setHovered(true)

            if(HoverOut.current)
            {
                HoverOut.current.restart();
            }

        }
    }

    function HandleHoverOut(e)
    {
        if(isMobile) return;
        if(props.phase == 0)
        {
            if(!r.current) return;
            if(r.current.material.opacity < .2) return;

            e.stopPropagation();
            setHovered(false);
            props.setHovered(false)

            if(HoverOut.current)
            {
                HoverOut.current.reverse();
            }

        }
    }

    useEffect(() =>
    {
        if(!complete) return;

        return () => 
        {
            shaderMatBack.dispose();
            shaderMatBody.dispose();
        }   
    }, [complete])

    if (complete) return null;

    return (
        <group  
        dispose={null} ref={ref}  {...props} position = {[endPos, -6, -10]}
        onPointerOver={(e) => 
            {
                HandleHover(e)
            }}
            onPointerOut={(e) =>
            {
                HandleHoverOut(e)
            }}
        >
            <group name="Scene"  >
                <group name="rig" >
                    <group name="HoneyBadger"  rotation = {[0, -Math.PI, 0]}>
                        <skinnedMesh
                        ref = {r}
                        frustumCulled = {false}
                        name="HoneyBadger_1"
                        geometry={nodes.HoneyBadger_1.geometry}
                        material={shaderMatBody}
                        skeleton={nodes.HoneyBadger_1.skeleton}
                        />
                        <skinnedMesh
                        ref = {r2}
                        frustumCulled = {false}
                        name="HoneyBadger_2"
                        geometry={nodes.HoneyBadger_2.geometry}
                        material={shaderMatBack}
                        skeleton={nodes.HoneyBadger_2.skeleton}
                        />
                        <skinnedMesh
                        ref = {r3}
                        frustumCulled = {false}
                        name="HoneyBadger_3"
                        geometry={nodes.HoneyBadger_3.geometry}
                        material={shaderMatBody}
                        skeleton={nodes.HoneyBadger_3.skeleton}
                        />
                    </group>
                    <primitive object={nodes.root} />
                    <primitive object={nodes['MCH-torsoparent']} />
                </group>
            </group>
        </group>
    );
};

useGLTF.preload('/hbupdated.glb')