import { fragmentShaderPhase1 as fragmentShader, vertexShader } from "../shadershb";
import { ShaderMaterial } from "three";

export const HBPhase1MatBody = () =>
{
    return new ShaderMaterial({
        extensions: 
        {
            derivatives: "#extension GL_OES_standard_derivatives : enable"
        },
        uniforms: {
            time: {value: 0},
            u_opacity: {value: 1},
            u_back: {value: 0.},
            u_hovered: {value: 1}
        },
        fragmentShader: fragmentShader,
        vertexShader: vertexShader,
        wireframe: false,
        transparent: true
    });
}

export const HBPhase1Back = () =>
{
    return new ShaderMaterial({
        extensions: 
        {
            derivatives: "#extension GL_OES_standard_derivatives : enable"
        },
        uniforms: {
            time: {value: 0},
            u_opacity: {value: 1},
            u_back: {value: 1.},
            u_hovered: {value: 1}
        },
        fragmentShader: fragmentShader,
        vertexShader: vertexShader,
        wireframe: false,
        transparent: true
    });
}