import '../../../css/TextStyles.css'
import { ScrollTrigger } from 'gsap/all';
import gsap from 'gsap';
import React from 'react';
import Create_Section1_Animations from './Animations/Section1_Animations';
import Create_Section2_Animations from './Animations/Section2_Animations';
import Section1_HTML from './HTMLContent/Section1_HTML';
import Section2_HTML from './HTMLContent/Section2_HTML';
import Section3_HTML from './HTMLContent/Section3_HTML';
import {Create_Section3_Animations} from './Animations/Section3_Animations';
import { useStoryBrandSectionContext } from './StoryBrandSectionWrapper';

gsap.registerPlugin(ScrollTrigger);
export default function StoryBrandSectionMain(props) 
{
    const expandTL =  React.useRef(null);
    const expandTL2 =  React.useRef(null);
    const expandTL3 =  React.useRef(null);

    const closeTL = React.useRef(null);
    const closeTL2 = React.useRef(null);
    const closeTL3 = React.useRef(null);

    const timelineRefs = {
        expandTL,
        expandTL2,
        expandTL3, 
        closeTL,
        closeTL2,
        closeTL3,
    }

    React.useEffect(() => {
        Create_Section1_Animations();
        Create_Section2_Animations();
        //Create_Section3_Animations();
    }, []);

    return (
        <React.Fragment>
            <Section1_HTML timelineRefs={timelineRefs} />
            {Section2_HTML()}
            {Section3_HTML()}
            <Create_Section3_Animations />
        </React.Fragment>
    );
}