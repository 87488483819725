import { useRef, useEffect, useMemo } from "react";
import { Object3D } from "three";
import * as THREE from 'three'
import { useFrame } from "@react-three/fiber";


/*
    Used on the home page to create the illusion of motion for the honey
    badger model.

    Props:
    total,
    position,
*/
export default function FlyingLinesInstanced(props)
{
    const SPEED = 40;
    const ref = useRef();
    const dummy = new Object3D();
    const SCOPEX = 76;
    const SCOPEY = 2;
    const SCOPEZ = 3;

    const STARTZ = -10
    let pX, pY, pZ; 

    let sW = (props.wScale == undefined) ? 1 : props.wScale

    useEffect(() =>
    {
        const colorArray = new Float32Array(props.total * 3);
        const color = new THREE.Color();
    
        for (let i = 0; i < props.total; i++) {
            // Randomly choose the color hex based on the condition
            const hex = Math.random() > 0.95 ? 0xFFFFFF : Math.random() > 0.45 ? 0xFADA5E : 0xFFFFFF;
            color.setHex(hex);
    
            // Set the color in the array
            color.toArray(colorArray, i * 3);
        }
    
        // If you have an attribute to set in your geometry, do it here
        // For example:
        ref.current.geometry.setAttribute('color', new THREE.InstancedBufferAttribute(colorArray, 3));
        ref.current.geometry.attributes.color.needsUpdate = true;

        let count = 0;

        for(count; count < props.total; count++)
        {
            //create initial object dispersion
            pX = Math.random() * SCOPEX * sW;
            pY = Math.random() * SCOPEY;
            pZ = -2

            if(Math.random() > 0.5)
            {
                pY *= -1;
            }

            dummy.position.set(pX, pY, pZ);
            dummy.updateMatrix();

            ref.current.setMatrixAt(count, dummy.matrix);
        }

        ref.current.instanceMatrix.needsUpdate = true;
    }, [props.total, SCOPEX, SCOPEY, SCOPEZ]);

    useFrame((_, delta) =>
    {
        if(ref.current == undefined) return;
        if(ref.current.material.opacity == 0) return;

        if(props.phase == 1 && ref.current.material.opacity > 0)
        {
            ref.current.material.opacity -= delta;
        }

        ref.current.position.x -= delta * SPEED / 2

        if(ref.current.position.x < -SCOPEX)
        {
            ref.current.position.x = props.position[0]
        }
    })

    //skip render
    if(ref.current != undefined)
    {
        if(ref.current.material.opacity == 0)
        {
            return;
        }
    }

    return(
        <instancedMesh
        ref = {ref}
        args = {[null, null, (props.total)]}
        frustumCulled = {false}
        position = {[props.position[0], props.position[1], props.position[2]]}>
            <boxGeometry args = {[.06,.01, .05]} />
            <meshBasicMaterial toneMapped = {false} vertexColors transparent/>
        </instancedMesh>
    )
}