import { PageWrapper } from "../PageManager";
import { BasicText, BasicHeading, BigHeading } from "../styledComponents";
import gsap from "gsap";
import { useEffect } from "react";

export default function Page2_1(props)
{

    useEffect(() =>
    {
        gsap.to(".t", {width: (props.docked == false) ? "50vmax" : "65vmax", opacity: 1})
    }, [props.docked])

    return(
        <PageWrapper docked = {props.docked == true ? "true" : "false"}>


            <BigHeading style = {{marginTop: "5vmax"}} className = {"inter" + " " + "fr" + " " + "t"}>
                Abstract
            </BigHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                &nbsp;&nbsp;&nbsp;&nbsp;Smart contract development is characterized by unique capabilities
                associated with the overarching blockchain medium.  However, scalability presents itself as an immediate and 
                broadly-encompassing challenge due to the universal immutability of code deployed to the blockchain.  The traditional solution 
                to this issue is to manually implement established design patterns such as 
                <i> proxy-delegate</i> or <i>eternal storage</i>.  However, these techniques introduce 
                considerable limitations of their own.  
                <br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;There are two critical drawbacks associated with established practices: 
                the aforementioned design patterns introduce permanent infrastructure that cannot 
                be easily extended after deployment, and the application of these patterns is manual.
                Project administrators are faced with a tricky dilemma: either allocate significant 
                resources to build the full range of complex features required to gain both strong scalability and both system and data 
                integrity, or 
                focus resources on the central concept while using basic peripheral systems.  Critically, these design 
                patterns introduce fixed layers to the contract system, necessitating a considerable effort to later 
                extend their capabilities or address vulnerabilities.
                
                <br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;
                
                Peripheral systems are typically built fresh for each project.  However, this is largely to keep 
                project scope in check: most applications would be better-served with a full-featured, highly-optimized solution
                that encompasses their immediate requirements while providing flexibility by offering a 
                wider range of additional capabilities.
                The HoneyBadger Storage System presents a worthy alternative to the status quo, 
                allowing developers to rapidly deploy and integrate highly-optimized peripheral infrastructure without sacrificing 
                security or control.  Our vision is not only to provide a powerful and widely-applicable base solution,
                but also to concretely address peripheral issues that limit the application of the eternal storage pattern, 
                opening the door to enhanced scalability, security, and efficiency to a wide subset of the web3 space.  This is achieved 
                through the use of purpose-built implementations, such as the HoneyBadgerDAO model, which uses community 
                governance to regulate administrator permissions and system updates, concretely minimizing the odds of foul play 
                while retaining the heightened capabilities necessary to support continued innovation.
                
                <br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;

                HoneyBadger supports all Solidity types and can securely maintain the state data for an entire contract system, 
                including global values and 
                mapped interfaces (mapping-of-struct pattern in base Solidity).  The system can host thousands of 
                partitioned storage spaces, which can be freely extended in a post-deployment setting to 
                fully accomodate upgrades to an existing smart contract ecosystem.
                HoneyBadger also provides highly-optimized implementations 
                for a nuanced range of features including batch storage and retrieval, malleable data structures, 
                and advanced permissioning.  Prominently-used features such as <i>get </i> and <i>put </i> operations 
                are optimized using proprietary techinques that bring gas costs to near the possible lower limit.  For instance, 
                storing a 256-bit value costs only 11,000 gas.  By leveraging HoneyBadger's outlined best practices, developers can 
                further reduce costs by efficiently structuring their storage instance to accomodate prominent access and modification patterns.
                By aggregating a powerful combination of advanced capabilities into 
                an approachable and easily-integrated system, HoneyBadger allows developers to fully capitalize on the benefits of 
                advanced architecture while freeing resources that can be allocated towards central project objectives.

            </BasicText>
        </PageWrapper>
    )
}