import React, { useEffect, useRef } from 'react';
import './IntroScroll.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { isMobile } from 'react-device-detect';
import { Canvas } from '@react-three/fiber';
import { HoneyBadgerModelBarebones, HoneyBadgerModelBarebones2, HoneyBadgerModelShinyDisplay } from '../../Models/HoneyBadgerModel';

import check from '../../../assets/gcheck.png'
import rocket from '../../../assets/rocket.png'

gsap.registerPlugin(ScrollTrigger);

export default function IntroScroll() {

    const ISMRef = useRef(null);

    useEffect(() => 
    {
        if(isMobile)
        {
            return;
        }
        const ISM = ISMRef.current;

        function getScrollAmount() {
            let width = ISM.scrollWidth;
            return width - window.innerWidth + 400;
        }

        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: ISM,
                start: 'top top',
                end: () => `+=${getScrollAmount()}`,
                pin: true,
                scrub: 1,
                invalidateOnRefresh: true,
            },
        })

        timeline.to(ISM, {
            x: () => -getScrollAmount(),
            ease: 'none',
        });

        timeline.to("#ult",
            {
                color: "yellow",
                duration: .3
            }, 0
        )

        timeline.fromTo("#uline",
            {opacity: 0, width: "0%"},
            {
                opacity: 1,
                width: "100%",
                duration: .15
            }, 0.1
        )

        timeline.to("#canvas",
            {
                marginLeft: "160%",
                duration: .45,
                keyframes: {
                    "0%": {marginTop: "20vh", opacity: 1},
                    "50%": {marginTop: "40vh", opacity: 1},
                    "75%": {opacity: 1},
                    "90%": {opacity: 1},
                    "100%": {marginTop: "0vh",
                        opacity: 0
                    }
                }
            },
            0
        )

        timeline.to("#rocket",
            {

                duration: .5,
                keyframes: {
                    "30%": {opacity: 0.5},
                    "60%": {opacity: 1, marginTop: "-60vh"}
                }
            }, "35%"
        )
    
        timeline.to("#sol", {color: "green", duration: .1}, "80%")

        timeline.fromTo("#check", {opacity: 0}, {opacity: 1, duration: .1}, "85%")

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    if(isMobile) return null;

    return (
        <div className='IntroScrollWrapper'>
            <div className='IntroScrollMain' ref={ISMRef}>
                <h2 style = {{marginLeft: "30vw"}}>The</h2>
                <h2 id = "ult">All-In-One</h2>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                    <h2>On-Chain</h2>
                    <hr 
                    id = "uline"
                    style={{ width: "0vw", border: "2.5vmin solid white", margin: 0, padding: 0 }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                    <img src = {rocket} style = {{position: "absolute", width: "10vw", opacity: 0}}
                        id = "rocket"
                    />
                    <h2>Infrastructure</h2>
                </div>
                <div style = {{display: "flex", flexDirection: "row"}}>
                    <h2 id = "sol">Solution</h2>
                    <img id = "check" src = {check} style = {{width: "7.5vmax", height: "7.5vmax",
                    alignSelf: "center"}} alt = 'green checkmark' 
                        
                    />
                </div>
                <Canvas 
                id = "canvas"
                style = {{width: "20vw", height: "20vh", zIndex: 1000,
                position: "absolute",
                marginTop: "40vh", marginLeft: "30vw"}}>
                    <ambientLight />
                    <HoneyBadgerModelBarebones2 position = {[0,0,4]} scale = {1}/>
                </Canvas>
            </div>

        </div>
    );
}