import { useFrame, useThree } from "@react-three/fiber";
import { useRef } from "react";
import MatrixRainingLetters from "./MatrixEffect2/MatrixEffect2";

/*
            <LavaLampBackground position = {[0, 0, 4.95]}/>
                        <Roadmap2SceneGeneric vis = {true}/>
                                    <HoneyBadgerShiny questSection = {3}/>
*/

export default function TestSpace()
{
    return(
        <>
            <div style = {{
                position: 'absolute',
                zIndex: 3,
                width: '100vw', 
                height: "100vh", 
                background: "grey", 
                opacity: 1,
                }}>
                <MatrixRainingLetters />
            </div>
            <div style = {{background: 'black', width: '100vw', height: '100vh'}}/>
        </>
        
    )
}

const CameraSettings = () =>
{
    const {camera} = useThree();

    camera.far = 60000;

    camera.updateProjectionMatrix()
    
}

const FL = () =>
{
    const {camera} = useThree();

    const ref = useRef()

    useFrame(() =>
    {
        if(!ref.current) return;

        ref.current.position.x = camera.position.x;
        ref.current.position.y = camera.position.y;
        ref.current.position.z = camera.position.z;
    })

    return(
        <pointLight ref = {ref} intensity = {15000}  distance = {50}/>
    )
}