import { PageWrapper } from "../PageManager";
import pd from '../../../../assets/proxydelegate.png'
import pd2 from '../../../../assets/proxydelegate1.png'
import ind from '../../../../assets/indexed2.png'

import gsap from "gsap";
import { useEffect } from "react";

import { BasicText, BasicHeading, BigHeading } from "../styledComponents";

export default function Page1_2_NEW(props)
{

    useEffect(() =>
    {
        gsap.to(".t", {width: (props.docked == false) ? "50vmax" : "65vmax", opacity: 1})
    }, [props.docked])


    return(
        <PageWrapper docked = {props.docked == true ? "true" : "false"}>

            <BigHeading style = {{marginTop: "5vmax"}} 
            className = {"inter" + " " + "fr" + " " + "t"}>
            Contemporary Scalability Solutions
            </BigHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left", fontSize: "1.2vmax"}} className = {"opensans" + " " + "t"}>
                
                The previous section <i>"Contextualizing HoneyBadger" &nbsp;</i>
                briefly characterizes contemporary  
                methods for enabling on-chain scalability, but primarily emphasized
                outlining the causes and implications of the scalability dilemma from a more fundamental standpoint.  This section provides
                readers with further context by fairly exploring current solutions in detail.  By 
                clearly understanding the benefits and drawbacks associated with present best practices, we can 
                more accurately discern both the factors that differentiate HoneyBadger and the unique value 
                that it provides. 


            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
            Exploring the Status Quo
            </BasicHeading>

            <BasicText style = {{marginTop: "0vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>
               
                At this point, we have already established a strong grasp
                of the central issue: introducing scalability to smart contract systems can be costly, 
                but it is most often necessary.
                Furthermore, current best practices have the potential to introduce rapidly-mounting costs:
                important capablities require a significant amount of resources, so companies
                 often reduce development expenses by minimizing efforts to enhance scalability.
                However, this permanently restricts the capabilities of their system:
                despite gaining some scalability-related advantages, it remains subject to limitations
                inherent to established design patterns, to an extent dictated by the quality of their 
                particular implementation.
                Presently, we only understand this dynamic at an abstract level, so it is important 
                to clearly outline what the established design patterns are, and why they 
                bring about serious challenges of their own.

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
            Proxy-Delegate Pattern: Interface-Imposed
            </BasicHeading>

            <img src = {pd} alt = "proxy delegate pattern explained" 
            style = {{width: "40vmax", border: "1px solid grey", borderRadius: "5px", marginTop: "2vmax"}} />

            <BasicText style = {{marginTop: "4vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                Proxy-delegate is the easiest, and likely the most widely-applied scalability solution.  
                Developers first create an interface of functions that they want their system to include.
                Let's take a moment to 
                understand what an "interface" actually is in this context, especially given the fact 
                that we used the same term to characterize indexed data in the previous section.
                In this context, interfaces define a set of functions
                that need to be implemented by contracts that <i>inherit</i> from that interface.  For instance, if we wanted
                to create a smart contract for a pet store, the interface may include functions like <i>bark(), 
                get_dog_name(integer dogId), get_dog_owner(integer dogId),</i> etc.  If a contract inherits the Dog interface, 
                then we know that we can invoke those functions from the contract. 

                <br/><br/>

                To implement the proxy-delegate pattern, we need to define a proxy and a delegate.   
                The proxy contract serves as a fixed 
                entry point to our contract system, and is linked to the delegate, which provides 
                an implementation for the functions defined in our interface. Critically, 
                data is stored within the proxy contract, while the delegate handles operating logic.  
                Often, both implement the same interface, but the proxy can also implement its own 
                logic as long as it maintains a reference to the interface that the delegate will use.  
                This allows the proxy to understand the logical layout of the delegate, despite the fact
                that they are separately-deployed contracts.  

                
                <br/><br/>

                This relatively straightforward pattern opens the door to scalability.  Given 
                that the delegate contract does not store any data, we are not tied to it in a post-deployment setting.  
                This means that we are free to update the proxy contract at any time, pointing it to a 
                different implementation of the delegate.  The new delegate shares the same interface as 
                the old delegate and the proxy, allowing the proxy to transition between 
                logical implementations while maintaining a fixed structure.  
            
            </BasicText>

            <img src = {pd2} alt = "proxy delegate pattern in action" style = {{width: "40vmax"}} />

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
            Interface-Imposed Proxy-Delegate Benefits
            </BasicHeading>

            <BasicText style = {{marginTop: "0vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>
                Proxy-delegate is a fast and functional solution: 
                a working implementation is quick to build, and will provide workable scalability support 
                within the scope of the immutable proxy contract.  Proxy-delegate systems can efficiently 
                address vulnerabilities and support updates, but without investing a significant 
                amount of resources to gain that privilege.  Even novice smart contract developers 
                can rapidly understand and implement the proxy-delegate pattern.  For many use cases, 
                it provides a workable solution that poses no significant problems on the surface.

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
            Interface-Imposed Proxy-Delegate Drawbacks
            </BasicHeading>

            <BasicText style = {{marginTop: "0vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                The most immediate problem with this solution is that the proxy contract itself
                is completely immutable.  Consequently, upgrades are only relative to that context: sure, you can improve 
                functions that are already defined in the shared interface, 
                but you can't smoothly introduce new functions within that scope.  For instance, if a developer wants 
                to introduce novel functionality to the delegate, it will need to be accessed independent of the proxy, or 
                through another function in the delegate that exists within the shared interface  While this is not necessarily
                a breaking issue, its obvious lack of optimality sets the stage for deeper problems associated with this design pattern.

                <br/><br/>

                At this point, we know that the proxy contract is essentially un-replaceable if it stores mapped data.
                  Furthermore, 
                it is feasible to introduce functions to the delegate that are absent in the shared interface, but 
                access will require independent delegate invocation, or invoking a function (that exists in the interface) designed to point towards 
                non-interfaced functions -- ie: <i>access_portal(uint256 functionId)</i>.  Correspondingly, our logical system is relatively unconstrained, 
                but unforeseen additions are likely to be complex and/or inefficient.  

                <br/><br/>

                Let's consider the consequences associated with our fixed proxy contract (in the 
                case that it stores mapped data, which is extremely likely).  Since the data interface 
                associated with those pre-defined mappings is fixed, our system will struggle to 
                adopt changes that require new data members.  For instance, if we start with the <i>user </i>
                interface: &#123; name, age, height &#125;, but want to add "balance," we will need to track 
                that value in a separate contract.  This means creating a new mapping in this separate 
                contract, then connecting it to a new logic contract, which now needs to interact with 
                two distinct contracts to accumulate the data pertaining to one user.  This means 
                significantly higher complexity and gas costs, and this same process will need to be 
                applied each time new values need to be tracked.  

                <br/><br/>

                An even bigger problem lies in the realm of security.  If a developer can simply 
                swap the logic contract at their whim, they can also introduce nefarious logic 
                whenever they want.  Instead of leveraging the implicit security provided by 
                the immutable blockchain-based contract instance,
                the safety of the project is now entirely contingent on a (potentially anonymous) 
                third party.  The solution to this issue is to gate changes behind 
                community governance, but this requires additional resources to implement 
                and is historically prone to vulnerabilities.

                <br/><br/>

                In conclusion, while proxy-delegate is a simple and accessible pattern, it has 
                significant flaws that will invariably cause problems down the line.  We have 
                fairly analyzed how developers could overcome these issues, but the result 
                is still a rigid, haphazard, and inefficient system that is largely 
                resistant to unforeseen changes.  To top it off, proxy-delegate does bring 
                some added effort, as it must be manually implemented to suit a particular 
                use case.  Building a system that is more amenable to change will require 
                a significantly higher allocation of resources, so most practical applications 
                work within the bounds of a basic implementation.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
            External Storage Pattern
            </BasicHeading>

            <BasicText style = {{marginTop: "0vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                The external storage pattern is a more complex solution to the scalability issue, 
                but is able to reward developers developers with superior flexibility and long-term 
                structural efficiency in comparison to the 
                proxy-delegate pattern.  As we previously established, a key issue with 
                proxy-delegate is the lack of flexibility offered by 
                the proxy contract.  Instead of creating true low-level malleability, 
                we simply impose a more relaxed limitation on our system.  
                <br/><br/>
                External storage 
                works a bit differently.  Instead of storing our data in a proxy contract 
                that may or may not share the <i>functional &nbsp;</i> interface of our delegate,
                we store it in a dedicated storage contract.  The storage contract 
                uses a permission structure to track which contracts can read and write 
                from its data access functions, imposing no extraneous restrictions on the type or layout 
                of connected logic.  The interface-imposing proxy pattern introduces a fixed interface 
                that is free from the hinderance of immutability, but ties us to it because 
                the proxy contract stores critical data that cannot be moved.  The streamlined proxy-delegate 
                model typically imposes an inefficient "spiderweb" contract pattern, while significantly increasing 
                the risk of data collissions.  The external storage pattern 
                introduces a storage contract that only stores and retrieves data. With a hefty amount of effort, 
                the external (officially labeled "eternal") storage pattern can be extended to support 
                the present and future data storage needs for a functionally-infinite quantity of logic contracts.
                Instead of a spiderweb pattern, our system is now a "hub-and-spoke" model, with a unified storage layer 
                providing efficient insertion and retrieval.  Critically, this unified layer is the best candidate 
                for efficient permission management and security measures, turning a contract system into a 
                cohesive unit that is both scalable and secure.

                
            </BasicText>


            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
            External Storage Benefits
            </BasicHeading>

            <BasicText style = {{marginTop: "0vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>
                
                The external storage pattern introduces fundamental scalability by fostering a fluid contract structure.  
                Instead of needing to pre-emptively determine how the system may evolve,
                developers simply build a system that can store and retrieve data, with some type of
                permissioning system to ensure that contracts can be attached or removed in production.  
                It can potentially be even easier to implement than proxy-delegate, simply 
                because the storage solution is left in the periphery of the logical system.  
                Reducing the inter-reliance of these system components allows developers to create 
                focused logic contracts without any added restriction barring the requirement 
                to store and retrieve data from the dedicated storage contract.  
            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
            External Storage Drawbacks
            </BasicHeading>

            <BasicText style = {{marginTop: "0vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>
                
                While external storage presents a fundamentally solid solution, it brings about 
                some considerable drawbacks of its own.  Firstly, external storage is typically even worse
                than proxy-delegate in terms of data integrity.  Not only do developers have the ability to 
                modify the contract system without any real restrictions, but they also 
                have direct control over data from the beginning, no longer needing to deploy a malicious logic 
                contract to gain this capability.  
                This begs the question: can this even be solved?  What benefit does a storage 
                system provide nobody can modify or access its data?  
                Additionally, given that a storage system is designed to 
                provide scalability, administrators need to retain 
                some permissions and be able to delegate permissions to new entities.  
                Simply put, most contracts that use this pattern do not maintain concrete integrity, simply on 
                account of these factors.  

                <br/><br/>

                Luckily, the issue of integrity can be solved by gating 
                user-level administrator actions behind community governance, 
                and barring non-contract addresses from gaining the ability to 
                read and write from the system.  In this case, administrators who 
                hold permissions are only able to create proposals for change, 
                allowing community members to verify the safety of the desired 
                changes before allowing them to be enacted.  This retains the 
                scalability of the system without sacrificing concrete integrity.  
                If data can only be accessed and modified by fixed logic contracts
                that have been verified as safe, the possibility of malicious 
                action is significantly diminished, though admittedly still conceivable 
                in some circumstances.

            </BasicText>

            <img src = {ind} alt = "indexed data" style = {{width: "40vmax", border: "1px solid grey", marginTop: "3vmax", borderRadius: "5px"}} />

            <BasicText style = {{marginTop: "4vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>
                
                Now let's cover the most significant issue with external storage.  While we are no longer
                tied to an immutable proxy contract, we now face the same situation in regards to the 
                storage system contract.  
                At first, this does not seem like a huge problem, since it doesn't impose any direct 
                limitations on our logical system.  But what happens when we want to 
                store more data?  

                <br/><br/>
 
                There are two high-level categories of data that smart contract systems deal with: global and indexed data (refer to the diagram above).  
                "Global data" refers to individual variables that are only defined once, and typically 
                used to represent overall system state.  Indexed data is 
                an interface of values that is uniquely populated for different entities.  For instance, 
                user-specific data would be indexed, with each user populating their own version 
                of the same interface.  We refer to this as "indexed" data because each  
                copy is stored and retrieved at its own location and accessible using a unique key. 
                For instance, if we assign each user their own integer value from zero, we could 
                access the 100th user's data with key 99.  

                <br/><br/>

                Global data is easy to port to a new contract instance, so long as 
                developers are able to view each value.  However, indexed data is either extremely 
                difficult or impossible to transfer, depending on the tools  
                the original contract has at its disposal.  In the best-case scenario, we 
                can have each user invoke a pre-built function that will accumulate 
                all of their data, then transfer that to the new contract instance.  This process is 
                called a "migration," and carries significant drawbacks.  For one, each user 
                is responsible for transferring their own data, which means they will need to pay 
                a non-negligible gas cost.  Hence, a migration forces users to spend money 
                for no perceived gain, under threat of losing their data, which they should 
                reasonably expect to be secured in the first place.  It's like if a bank 
                forced you to spend money for the privilege of keeping your money: 
                it's a direct betrayal of your reasonable expectations.  

                <br/><br/>

                With this context in mind, the big problem with external storage becomes clear:
                we are completely tied to the data interface and capabilities that we defined on launch day.  Let's say 
                we create a new logic contract that requires additional user data fields.  We want this data to 
                share the same indexing structure (ie; each user is mapped to the same ID representing them in the existing contract) 
                but we cannot simply extend the original data interface because it is immutable.  In this case, 
                the only option is to create a new storage system instance that includes those specific values.  Consequently, 
                our logic contract will need to make an additional context swap (self, storage1, storage2) to access a 
                user's aggregate data.  This problem continues to escalate in severity as new upgrades are needed, 
                resulting in an even more convoluted contract structure and access patterns.  Furthermore, 
                this represents only one problematic circumstance that can arise.  For instance, what can we do 
                if a populated storage contract has a vulnerability?  The only solution is to perform a migration.

                <br/><br/>

                So how can we solve the first problem?  The answer is by introducing the ability 
                to extend the data interface of our storage system after deployment.  However, this simple change 
                requires a massive leap in terms of complexity.  Since Solidity does not support  
                interface (more clearly, struct) extension by default, we need to create our own abstraction.  This means manually 
                managing the storage space, creating a hashing scheme, actually implementing 
                our interface, adding support for all of the types, adding put and get functionality, 
                and THEN adding the ability to 
                extend the interface.  The scale of our project has grown exponentially, and none of this 
                effort will get us any closer to our main objectives (bear in mind that the storage system is 
                only supporting infrastructure).  Even worse,
                this is a massive undertaking, even for experienced developers.  Not only does the system 
                need to work, but it needs to be completely free from vulnerabilities AND operate efficiently.  
                Vulnerabilities cannot be fixed because our storage layer is immutable, and 
                inefficiency means higher costs for users, which will irrevocably reduce user engagement.  
                The reduction in engagement is further compounded by the centrality of our storage system: 
                since most of our logic contract functions will want to manipulate -- or at least access -- 
                data, storage system inefficiency will propagate through the entire system.  


                <br/><br/>

                The main problem with external storage is not that a perfect external storage solution 
                wouldn't be an excellent solution to smart contract scalability, it's that the ideal implementation is a 
                massive undertaking of its own.  Given that these systems are peripheral to the central objectives 
                of the projects that implement them, few want to expend a huge amount of resources when they could
                reach production with a basic model, facing no immediate reprucussions.  
                The issue only becomes apparent when developers 
                want to extend or upgrade the system.  This is why so many projects launch with weak scalability, 
                build community hype around their initial system, then stagnate and die off.  Often, it isn't that 
                the developers are too lazy to continue development, it's that their system is barely amemable to 
                further improvements or additions.  Admitting this openly would cripple the chances for enduring success, 
                so the narrative is simply that "development is hard."

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
            Ownership Renunciation
            </BasicHeading>

            <BasicText style = {{marginTop: "0vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>
                
                Ownership renunciation is a deservedly-respected solution to the scalability problem.  
                Many communities appreciate the fact that administrators are willing to completely 
                forego their heightened permissions in order to maximize the integrity of their system.  
                However, an equally valid perspective is that renunciation most often cripples the 
                enduring competitiveness of a project.  In some ways, renunciation amounts to direct avoidance of 
                the scalability problem, framed in such a way that these absent capabilities are 
                viewed in a positive light.  Let's face it: is any system really better off without the ability 
                to support future innovation or address vulnerabilities, absent a migration event?  If the same system 
                could have those capabilities without providing administrators direct low-level control, 
                it would almost always be much better off in the long run.  

                <br/><br/>

                However, this alternative perspective is not meant to detract from projects with 
                renounced ownership, but simply to point out clear flaws with this  
                solution.  When considering the state of contemporary scalability solutions, 
                the benefits of renunciation become abundantly clear.  As we have discovered, 
                a basic scalability solution implementation will invariably bring about its own 
                drawbacks, and solving those problems is a massive undertaking of its own.  
                Projects with renounced ownership have more resources to allocate towards 
                their central objectives, which can easily lead to a more polished launch-day system.  Furthermore, 
                the smart contract ecosystem is indeed only one element of a web3 project.  Developers of renounced 
                projects can still improve the user experience and even introduce additional features by creating 
                new smart contracts.  However, this is still within the context of a more fundamental 
                lack of agency over their existing ecosystem, and the risk of yet-to-be-discovered 
                vulnerabilities can seldom be conclusively ruled out. 
                
                <br/><br/>

                Overall, it is reasonable to posit that most systems would greatly benefit 
                from access to more capabiltiies, but often not to the extent 
                that would justify the massive resource expenditure necessary to 
                 realize those goals in the contemporary market landscape.  This is a key reason why HoneyBadger is 
                such a groundbreaking solution: by making advanced capabilities immediately accessible
                while preserving concrete logical and data integrity, smart contract 
                developers benefit from the best of both worlds, and without making any 
                sacrifices.

            </BasicText>

        </PageWrapper>
    )
}


/*

            <h1 style = {{fontSize: "2vmax", color: "white", marginTop: "4vmax"}}
            className = "inter">Imagining An Alternative Solution</h1>

            <p className = "opensans" style = {{ color: "#e3e3e3", width: "40vmax"}}>

                &nbsp;&nbsp;&nbsp;&nbsp;
                We have thoroughly established the benefits and drawbacks of the most popular 
                scalability solutions.  While they work to some extent, established patterns 
                impose their own fixed limitations. Essentially, the issue of scalability is 
                pushed back into the future: the limitations of these solutions become very apparent when developers want to 
                make changes that they hadn't originally considered.  Furthermore, there are 
                considerable drawbacks in terms of system integrity.  If individuals can modify 
                logic and data at their unchecked discretion, the integrity of the system 
                lies entirely in their hands.  

                <br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;
                Luckily, these issues can be solved by adding new layers to the system.  
                As we previously mentioned, the issue of integrity can be solved 
                with community governance.  Rigidity can be addressed by providing 
                the ability to extend indexed data structures.  However, implementing a 
                peripheral system that covers these bases is a costly task, which is why 
                most projects opt out of using these patterns altogether. 

                <br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;
                Logically, the solution to this dilemma is to introduce a 
                universally-applicable system that concretely solves the issues 
                that hinder the adoption of scalability architecture.  In this scenario, 
                developers could simply deploy an instance of a pre-built, optimized external storage system.
                Instead of spending time and capital to build a partially-complete solution, 
                they could invest those assets into forward-facing elements of their 
                project, while immediately benefitting from top-notch peripheral architecture.

                <br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;

                Building a universal system that can withstand the test of 
                time requires some unique considerations.  As we have learned, 
                established design patterns introduce their own limitations in a post-deployment environment.  Likewise, 
                a universal solution has the potential to introduce drawbacks of its own.  
                Hence, it is worthwhile to first investigate factors that could 
                reduce the viability of a universal solution so that we can 
                accurately judge the HoneyBadger system as it is. 
            </p>

            <h1 style = {{fontSize: "1.5vmax", color: "white", marginTop: "4vmax"}}
            className = "inter">Gas Costs</h1>

            <p className = "opensans" style = {{ color: "#e3e3e3", width: "40vmax"}}>
                
                &nbsp;&nbsp;&nbsp;&nbsp;
                Ideally, a universal solution should employ the full 
                range of techniques available to reduce transaction costs, regardless of the added difficulty.  
                As previously mentioned, most scalability solutions are only partially complete, 
                simply on account of the cost of further development.  However, it can still be concerning
                to completely forsake that purpose-built building process in favor of a universal solution. 
                If developers feel that their own implementation would be significantly more efficient, 
                that is a valid reason not to use the universal solution.

                <br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;
                There is a lower limit to the cost of 
                any transaction because the logical operations it includes have fixed costs.  For instance, if we want to 
                store a value to the storage system, we will always have to invoke <i>sstore.</i> This 
                is the most expensive operation in Solidity, so careful consideration should be made to 
                minimize the amount of times it is invoked.  In this way, the universal solution should ensure that its 
                functions are near the possible lower limit of cost, thereby ensuring that it will remain competitive 
                in comparison to possible future implementations.  This is especially important given that 
                storage systems hold critical data, and hence cannot easily be replaced after deployment.  

            </p>


            <h1 style = {{fontSize: "1.5vmax", color: "white", marginTop: "4vmax"}}
            className = "inter">Logical and Data Integrity</h1>

            <p className = "opensans" style = {{ color: "#e3e3e3", width: "40vmax"}}>
                
                &nbsp;&nbsp;&nbsp;&nbsp;
                The issue of unmetered administrative power has the potential to be even more 
                prevalent within the context of a universal system, given the fact that it 
                will necessarily provide a wide range of features.  Furthermore, it is important 
                to recognize that employing a single method to comprehensively solve this problem is likely 
                not feasible.  For instance, the DAO model allocates administrator authority to the community.  Hence, 
                these projects would require full community control over the universal solution as well.  However, an 
                NFT exchange is not responsible for holding much user data, but it will greatly benefit from 
                the ability to rapidly deploy logical updates.  In this scenario, a more streamlined, but 
                unmetered administrative structure would be appropriate: developers can have that authority 
                without sacrificing community trust.  Consequently, a universal solution should be core-universal, not 
                fully universal.  Features such as highly-optimized insertion and retrieval and dataset expansion 
                have far-reaching utility, but permission management and governance should be purpose-built  
                to handle specific project requirements.
            </p>

            <h1 style = {{fontSize: "1.5vmax", color: "white", marginTop: "4vmax"}}
            className = "inter">Security and Restrictions</h1>

            <p className = "opensans" style = {{ color: "#e3e3e3", width: "40vmax"}}>
                
                &nbsp;&nbsp;&nbsp;&nbsp;
                Given the fact that the universal solution will be difficult to replace in a 
                post-deployment setting (same reasoning as for the other scalability patterns), 
                it is critical that security considerations are thoroughly addressed.  First, 
                it is important to consider potential attack vectors for this 
                theoretical system.  We know that the most fundamental utility the system provides is 
                storing and retrieving data.  If non-permissioned entities can leverage storage and retrieval, 
                the entire contract system can easily be compromised through that entry point.
                    The same risk applies to control structures such as 
                the permission management system.  
                <br/><br/>

                &nbsp;&nbsp;&nbsp;&nbsp;
                All considered, entities lacking express permissions (such as normal users) should have no 
                privileges within the system at all: they will interact with the system indirectly by 
                invoking functions within contracts that are linked to the universal solution, but never 
                directly engage with the storage layer itself.  The storage layer is almost always present, 
                but it is under the user's view.  Consequently, we need to impose 
                strict access checks on the entire system, only allowing explicitly-permissioned entities  
                to to invoke its functions.  

                <br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;
                This security solution is like locking all of the gates to a castle.  By providing access 
                only to users with express privileges, we are giving the gate keys to the 
                individuals who actually deploy and own a given instance of the system.  This way, 
                the universal solution does not impose any extraneous restrictions of its own, 
                yet simultaneously renders it impossible for intruders to enter UNLESS they are 
                let in by the guards (more clearly, the administrators of our hypothetical 
                project using the system).  

                <br/><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;
                There is also the case where a project does not want indviduals to hold 
                privileges in the system.  Instead, only logic contracts are able to invoke its functions. 
                So long as the code for these contracts is open-sourced, this effectively removes 
                the grounds for concern regarding administrator authority.  Permission changes 
                and updates to the contract system are handled by creating and submitting a 
                community proposal, which is automatically enacted by the system if approved.  
                Here, the head administrator retains rights to submit proposals, but 
                non-contract addresses are barred from gaining active authority.  Since the 
                code that drives these systems is openly-available, users can concretely 
                verify that foul play is impossible. 

            </p>

*/