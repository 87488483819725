import { useEffect, useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import HoneyBadgerModel from "../Models/HoneyBadgerModel";
import easeInOutQuad from '../Helpers/easeInOutQuad'
import { lerp } from "three/src/math/MathUtils";
import { AspectManager } from "../Helpers/ClipManager";
import { deviceType } from "react-device-detect";
import { HoneyBadgerModelMain } from "../Models/HoneyBadgerModelMain";


export default function HoneyBadgerModelHandler(props)
{
    const badgerRef = useRef()
    const x = useRef()
    const y = useRef()

    const onMouseMove = (event) =>
    {
        const {clientX, clientY} = event;

        x.current = (clientX / window.innerWidth);
        y.current = -1 - (clientY / window.innerHeight);
    }

    useEffect(() =>
    {
        if(deviceType === "browser")
        {
            window.addEventListener('mousemove', onMouseMove);

            return() =>
            {
                window.removeEventListener('mousemove', onMouseMove);
            };
        }

    }, [])


    return(
        <HoneyBadgerModelMain
        setSwapTime = {(v) => {props.setSwapTime(v)}}
        phase = {props.phase}
        hasTransitioned = {props.hasTransitioned}
        setTransitioned = {(v) => {props.setTransitioned(v)}}
        ref = {badgerRef} 
        scale = {CalculateScale()} 
        rotation = {[0.0, -Math.PI / 2,0]} 
        setHovered = {(v) => {props.setHovered(v)}}
        setPhase = {(v) => {props.setPhase(v)}}
        />
    )
}

export const CalculateScale = () =>
{
    let w = window.innerWidth;
    let h = window.innerHeight;

    let aspect = w / h;

    if(w > h)
    {
        if(aspect > 1.7)
        {
            return 25;
        }
        else if(aspect > 1.4)
        {
            return 23.5;
        }
        else
        {
            return 20;
        }
    }
    else
    {
        if(aspect > .9)
        {
            return 20;
        }
        else if(aspect > .8)
        {
            return 17;
        }
        else if(aspect > .7)
        {
            return 16;
        }
        else if(aspect > .6)
        {
            return 15;
        }
        else if(aspect > .5)
        {
            return 13;
        }
        else if(aspect > .4)
        {
            return 10;
        }
        else
        {
            return 7;
        }
    }

}
