import React, { useState } from "react";
import { functions } from "./fireBaseConfig";
import { httpsCallable } from "firebase/functions";

const SignIn = ({onSignIn}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validateWhitelist = httpsCallable(functions, "validateWhitelist");

    try {
      const result = await validateWhitelist({ email, password });
      console.log("Result from validateWhitelist:", result);
      if (result.data.success) {
        setError("");
        onSignIn({ email }); // Call the callback with user data
        // Redirect or update UI on successful login
        console.log("Login successful!");
      } else {
        setError(result.data.message);
      }
    } catch (err) {
      console.error("Error calling validateWhitelist:", err);
      setError(err.message);
    }
  };

  return (
    <div style={{ width: "100vw", height: "100vh", background: "black", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <h1 style={{ marginTop: "30vh", color: "red" }}>HoneyBadger</h1>
      <h1 style={{ marginTop: "0vh", color: "white", textAlign: "center" }}>Sign In</h1>
      <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          style={{ width: "20vw", marginTop: "5vh" }}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={{ width: "20vw", marginTop: "2vh" }}
        />
        <button type="submit" style={{ marginTop: "2vh" }}>Submit</button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default SignIn;