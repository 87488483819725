import { BackSide, Color, DoubleSide, ShaderMaterial } from "three";
import { fragment, fragment2, vertex } from "./LavaLampShaders";
import { useMemo, useRef } from "react";
import { useFrame } from "@react-three/fiber";

export default function LavaLampBackground(props)
{
    const ref = useRef();

    var colors = require('nice-color-palettes');

    //let random_index = Math.floor(Math.random() * colors.length) 10 91
    let palette = colors[91];
    palette = palette.map((color) => new Color(color));

    const uniforms = useMemo(() =>
    {
        return(
            {
                u_time: {value: 0},
                u_color: {value: palette},
                u_opacity: {value: 0}
            }
        )

    }, [])

    useFrame((_, delta) =>
    {
        ref.current.material.uniforms.u_time.value = ref.current.material.uniforms.u_time.value + delta;
        
    })

    return(
        <mesh {...props} ref = {ref}>
            <shaderMaterial 
            fragmentShader = {fragment}
            vertexShader = {vertex}
            uniforms = {uniforms}
            transparent = {true}
            />
            <coneGeometry args = {[8, 8, 256, 128]}/>
        </mesh>

    )
}


export function LavaLampBackground2(props)
{
    const ref = useRef();

    var colors = require('nice-color-palettes');

    //let random_index = Math.floor(Math.random() * colors.length) 10 91
    let palette = colors[91];
    palette = palette.map((color) => new Color(color));

    const uniforms = useMemo(() =>
    {
        return(
            {
                u_time: {value: 0},
                u_color: {value: palette},
                u_opacity: {value: 0}
            }
        )

    }, [])

    useFrame((_, delta) =>
    {
        ref.current.material.uniforms.u_time.value = ref.current.material.uniforms.u_time.value + delta;
        
    })

    return(
        <mesh {...props} ref = {ref}>
            <shaderMaterial 
            fragmentShader = {fragment}
            vertexShader = {vertex}
            uniforms = {uniforms}
            transparent = {true}
            />
            <planeGeometry args = {[10, 10, 100, 100]}/>
        </mesh>

    )
}

export function LavaLampBackgroundTest(props)
{
    const ref = useRef();

    var colors = require('nice-color-palettes');

    let random_index = Math.floor(Math.random() * colors.length)
    let palette = colors[random_index];
    palette = palette.map((color) => new Color(color));

    console.log(random_index);

    const uniforms = useMemo(() =>
    {
        return(
            {
                u_time: {value: 0},
                u_color: {value: palette},
                u_opacity: {value: 0}
            }
        )

    }, [])

    useFrame((_, delta) =>
    {
        ref.current.material.uniforms.u_time.value = ref.current.material.uniforms.u_time.value + delta;
        
    })

    return(
        <mesh {...props} ref = {ref}>
            <shaderMaterial 
            fragmentShader = {fragment}
            vertexShader = {vertex}
            uniforms = {uniforms}
            transparent = {true}
            />
            <planeGeometry args = {[100, 100, 200, 200]}/>
        </mesh>

    )
}

export function LavaLampBackgroundSphere(props)
{
    const ref = useRef();

    var colors = require('nice-color-palettes');

    let random_index = Math.floor(Math.random() * colors.length)
    let palette = colors[42];
    palette = palette.map((color) => new Color(color));

    const uniforms = useMemo(() =>
    {
        return(
            {
                u_time: {value: 0},
                u_color: {value: palette},
                u_opacity: {value: 0}
            }
        )

    }, [])

    useFrame((_, delta) =>
    {
        ref.current.material.uniforms.u_time.value = ref.current.material.uniforms.u_time.value + delta;
        ref.current.rotation.z += delta;
    })

    return(
        <group {...props}>
            <mesh  ref = {ref} >
                <shaderMaterial 
                side={DoubleSide}
                fragmentShader = {fragment}
                vertexShader = {vertex}
                uniforms = {uniforms}
                transparent = {true}
                />
                <sphereGeometry args = {[100, 120, 120]}/>
            </mesh>
        </group>

    )
}

export function LavaLampBackgroundCircle(props)
{
    const ref = useRef();

    var colors = require('nice-color-palettes');

    let random_index = Math.floor(Math.random() * colors.length)
    let palette = colors[42];
    palette = palette.map((color) => new Color(color));

    const uniforms = useMemo(() =>
    {
        return(
            {
                u_time: {value: 0},
                u_color: {value: palette},
                u_opacity: {value: 0}
            }
        )

    }, [])

    useFrame((_, delta) =>
    {
        ref.current.material.uniforms.u_time.value = ref.current.material.uniforms.u_time.value + delta;
        
    })

    return(
        <mesh {...props} ref = {ref} >
            <shaderMaterial 
            side={BackSide}
            fragmentShader = {fragment}
            vertexShader = {vertex}
            uniforms = {uniforms}
            transparent = {true}
            />
            <torusGeometry args = {[100, 3, 120, 120]}/>
        </mesh>

    )
}

export function LavaLampBackgroundPlane(props)
{
    const ref = useRef();
    const timer = useRef(0);

    var colors = require('nice-color-palettes');

    let random_index = Math.floor(Math.random() * colors.length)
    random_index = 22;

    let palette = colors[random_index];
    palette = palette.map((color) => new Color(color));

    let palette2 = colors[91];
    palette2 = palette2.map((color) => new Color(color));

    const uniforms = useMemo(() =>
    {
        return(
            {
                u_time: {value: 0},
                u_color: {value: palette},
                u_color2: {value: palette2},
                u_opacity: {value: 0}
            }
        )

    }, [])

    useFrame((_, delta) =>
    {
        ref.current.material.uniforms.u_time.value = ref.current.material.uniforms.u_time.value + delta;

        if(props.phase == 1)
        {
            if(timer.current < 10)
            {
                timer.current = timer.current + delta;
                return;
            }
            if(ref.current.material.uniforms.u_opacity.value < .06)
            {
                ref.current.material.uniforms.u_opacity.value = ref.current.material.uniforms.u_opacity.value + delta * .025;
            }
        }
        if(props.questSection != null)
        {
            if(ref.current.material.uniforms.u_opacity.value < .1)
            {
                ref.current.material.uniforms.u_opacity.value = ref.current.material.uniforms.u_opacity.value + delta * .025;
            }
        }
    })

    return(
        <mesh {...props} ref = {ref}>
            <shaderMaterial 
            fragmentShader = {fragment2}
            vertexShader = {vertex}
            uniforms = {uniforms}
            transparent = {true}
            />
            <planeGeometry args = {[300, 300, 100, 100]} />
        </mesh>

    )


}