import '../../css/marquee.css'
import '../../css/TextStyles.css'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import '../../css/App.css'
import gsap from 'gsap'
import '../../css/Menus/CoolerMenu.css'
import homeicon from '../../assets/homeicon.png'
import interfaceicon from '../../assets/interfaceicon.png'
import documentationicon from '../../assets/documentationicon.png'
import { useRef } from 'react'
import tglogo from '../../assets/tglogo.png'
import legal from '../../assets/legal.png'
import JoinCommunity from './gifAnimations/joinCommunity'
import services from '../../assets/services.gif'

import xlogo from '../../assets/xlogo.jpeg'


export function CoolerMenu(props)
{
    const itemRef = useRef([])
    const loaded = useRef(false)

    const [joinCommunityHovered, setJoinCommunityHovered] = useState(false)

    //display menu
    useEffect(() =>
    {
        if(loaded.current === false)
        {
            gsap.fromTo(".Row", 
                {marginLeft: "-=10vw"}, 
                {marginLeft: "0vw", opacity: 1, duration: 1.25, stagger: 0.33, delay: 2})
            loaded.current = true;
        }
    }, [])

    //set ambient on the current page
    useEffect(() =>
    {
        if(props.currentPage === undefined) return;

        let ambient = gsap.timeline({paused: true, repeat: -1, yoyo: true})
        ambient.to(props.currentPage, {
            duration: 1,
            boxShadow: '0 0 5px #b4bfd9, 0 0 2px #b4bfd9, 0 0 4px #b4bfd9 0 0 7px #b4bfd9',
            ease: 'none',
        })
        .to(props.currentPage, {
            duration: 1, 
            boxShadow: '0 0 5px #b4bfd9, 0 0 8px #b4bfd9, 0 0 12px #b4bfd9, 0 0 16px #b4bfd9',
            ease: 'none',
        }) 
        .to(props.currentPage, {
            duration: 1, 
            boxShadow: '0 0 12px #b4bfd9, 0 0 12px #b4bfd9, 0 0 10px #b4bfd9, 0 0 20px #b4bfd9',
            ease: 'none',
        }); 

        ambient.play();

    }, [props.currentPage])


    //set up hovering
    useEffect(() =>
    {

        itemRef.current.forEach((item) =>
        {
            const hoverAnim = gsap.timeline({paused: true})

            const id = item.id;

            //# to #((r1 >> i1)dt)
            let textId = "#" + id + "dt"

            hoverAnim.to(item, {
                scale: 1.2, 
                ease: "power1.inOut",
                immediateRender: false
            })
            .to(textId, {opacity: 1, scale: 1, delay: .5}, "<")


            item.addEventListener('pointerover', () => 
            {
                if(id == "i5")
                {
                    setJoinCommunityHovered(true)
                }

                if(props.phase != 0)
                {
                    hoverAnim.pause();
                    return;
                }
                else
                {
                    hoverAnim.play()
                }

            });
            item.addEventListener('pointerout', () => 
                {
                    hoverAnim.reverse()

                    if(id == "i5")
                    {
                        setJoinCommunityHovered(false)
                    }
                });
        })

    }, [props.phase])


    return(
        <>
            <div className='CoolerMenuContainer' >
                <div className='Row' id = "r1" >
                    <div className= {(props.currentPage == "#i1") ? "CoolerMenuItem PermBoxShadow" : "CoolerMenuItem"}
                    id = "i1" ref={el => itemRef.current[0] = el}>
                        <Link to = "/" style = {{textDecoration: "none", pointerEvents: "all"}} >
                            <img className = "img" src = {homeicon} id = "imgi1"  alt = "home page" />
                        </Link>
                    </div>
                    <h2 className='DescriptorText inter' id = "i1dt">
                        <b>Home</b><br/>
                        <hr style = {{width: "100%"}} />
                        <span style = {{color: "grey"}}>
                            Interactive walkthrough
                        </span>
                    </h2>
                </div>

                <div className='Row' id = "r2">
                    <div className= {(props.currentPage == "#i2") ? "CoolerMenuItem PermBoxShadow" : "CoolerMenuItem"}
                    id = "i2" ref={el => itemRef.current[1] = el}>
                        <Link to = "/get" style = {{textDecoration: "none", pointerEvents: "all"}}>
                            <img className = "img" src = {interfaceicon}   alt = "web3 interface" />
                        </Link>
                    </div>
                    <h2 className='DescriptorText opensans' id = "i2dt">
                        <b>Web3 Interface</b><br/>
                        <hr style = {{width: "100%"}} />
                        <span style = {{color: "grey"}}>
                        • Control Panel<br/>
                        • Deployment Panel<br/>
                        • P2P Market<br/>
                        </span>
                    </h2>

                </div>


                <div className='Row' id = "r3">
                    <div className= {(props.currentPage == "#i3") ? "CoolerMenuItem PermBoxShadow" : "CoolerMenuItem"}
                    id = "i3" ref={el => itemRef.current[2] = el}>
                        <Link to = "/docs" style = {{textDecoration: "none", pointerEvents: "all", zIndex: 1000}}>
                            <img className = "img"  src = {documentationicon}  alt = "documentation" />
                        </Link>
                    </div>
                    <h2 className='DescriptorTextLong opensans' id = "i3dt">
                        <b>Documentation</b><br/>
                        <hr style = {{width: "100%"}} />
                        <span style = {{color: 'grey'}}>
                            
                            Learn about the HoneyBadger system at your own pace.
                            

                        </span>
                    </h2>
                </div>


                <div className = "Row" id = "r4">
                    <div className= {(props.currentPage == "#i4") ? "CoolerMenuItem PermBoxShadow" : "CoolerMenuItem"} 
                    id = "i4" ref={el => itemRef.current[3] = el}>
                        <img className = "img" src = {legal}  style = {{cursor: "pointer"}} alt = "legal page"/>
                        </div>
                        <h2 className='DescriptorText opensans' id = "i4dt">
                            <b>Disclaimers</b><br/>
                            <hr style = {{width: "100%"}} />
                            <span style = {{color: "grey"}}>

                                Terms, disclaimers, legal documents.
                            
                            </span>
                        </h2>
                </div>


                <div className = "Row" id = "r5">
                    <div className= {(props.currentPage == "#i5") ? "CoolerMenuItem PermBoxShadow" : "CoolerMenuItem"}
                    id = "i5" ref={el => itemRef.current[4] = el}>
                        <img className = "img" src = {tglogo} alt = "telegram channel" style = {{cursor: "pointer"}} />
                        </div>
                        <h2 className='DescriptorText opensans' id = "i5dt">
                            <b>Telegram</b><br/>
                            <hr style = {{width: "100%"}} />
                            <span style = {{color: "grey"}}>

                                Join the HoneyBadger community!  

                            </span>
                        </h2>
                </div>


            </div>
            <JoinCommunity hovered = {joinCommunityHovered}/>
        </>
    )
}



export function Menu(props)
{
    const [hovered, setHovered] = useState(0)


    useEffect(() =>
    {
        const fade_in_anim = gsap.to("#container", {opacity: 1, marginTop: "0vmax", duration: .75, delay: 0});
    }, [])



    return(
        <div
        id = "container"
        style = {{
        position: "absolute",
        display: 'flex',
        flexDirection: "row", 
        width: '100vw', 
        opacity: 0,
        marginTop: "-4vmax",
        zIndex: 35, 
        alignItems: 'center', 
        justifyContent: "center",
}}>
            <Link to = "/" style = {{textDecoration: "none"}} 
                onMouseOver={() =>
                {
                    setHovered(1)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}
            >
                <h1 className = "orbitron" style = {{
                fontSize: "1.2vmax",
                cursor: "pointer", 
                marginLeft: "17vmax",
                color: (props.page == 0) ? "grey" : (hovered == 1) ? "#a6a6a6" : "white"}}
                >Home</h1>
            </Link>
            <Link to = "/get" style = {{textDecoration: "none"}}
                onMouseOver={() =>
                {
                    setHovered(2)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}>
                <h1 className = "orbitron" style = {{
                fontSize: "1.2vmax", 
                marginLeft: "2vmax",
                cursor: "pointer", 
                color: (props.page == 1) ? "white" : (hovered == 2) ? "#a6a6a6" : "white"}}>HoneyBadger</h1>
            </Link>
            <Link to = "/docs" style = {{textDecoration: "none"}}
                onMouseOver={() =>
                {
                    setHovered(3)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}>
                <h1 className = "orbitron" style = {{
                fontSize: "1.2vmax", 
                marginLeft: "2vmax",
                cursor: "pointer", 
                color: (props.page == 2) ? "white" : (hovered == 3) ? "#a6a6a6" : "white"}}>Docs</h1>
            </Link>

            <h1 className = "orbitron"                 
            
                onMouseOver={() =>
                {
                    setHovered(4)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}
                style = {{
            fontSize: "1.2vmax", 
            cursor: "pointer", 
            marginLeft: "2vmax",
            color: (hovered == 4) ? "#a6a6a6" : "white"}}>Join Telegram</h1>


            <h1 className = "orbitron" 
                onMouseOver={() =>
                {
                    setHovered(5)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}
                style = {{
            fontSize: "1.2vmax", 
            marginLeft: "2vmax",
            cursor: "pointer", 
            color: (hovered == 5) ? "#a6a6a6" : "white" }}>Disclaimer</h1>
        </div>
    )
}
export function MenuNoLogo(props)
{
    const [hovered, setHovered] = useState(0)

    return(
        <div style = {{position: "absolute",
         display: 'flex',
        flexDirection: "row", 
        width: '100vw', 
        zIndex: 35, 
        alignItems: 'center', 
        justifyContent: "center",
}}>
        

            <Link to = "/" style = {{textDecoration: "none"}} 
                onMouseOver={() =>
                {
                    setHovered(1)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}
            >
                <h1 className = "lightFuturistic" style = {{
                fontSize: "1.2vmax",
                cursor: "pointer", 
                marginLeft: "2vmax",
                color: (props.page == 0) ? "white" : "grey"}}
                >Home</h1>
            </Link>
            <Link to = "/get" style = {{textDecoration: "none"}}
                onMouseOver={() =>
                {
                    setHovered(2)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}>
                <h1 className = "lightFuturistic" style = {{
                fontSize: "1.2vmax", 
                marginLeft: "2vmax",
                cursor: "pointer", 
                color: (props.page == 1) ? "white" : "grey"}}>HoneyBadger</h1>
            </Link>
            <Link to = "/learn" style = {{textDecoration: "none"}}>
                <h1 className = "lightFuturistic" style = {{
                fontSize: "1.2vmax", 
                marginLeft: "2vmax",
                cursor: "pointer", 
                color: (props.page == 2) ? "white" : "grey"}}>Learn</h1>
            </Link>

            <h1 className = "lightFuturistic"                 
            
                onMouseOver={() =>
                {
                    setHovered(1)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}
                style = {{
            fontSize: "1.2vmax", 
            cursor: "pointer", 
            marginLeft: "2vmax",
            color: "grey"}}>Join Telegram</h1>


            <h1 className = "lightFuturistic" 
                onMouseOver={() =>
                {
                    setHovered(1)
                }}
                onMouseOut={() =>
                {
                    setHovered(0)
                }}
                style = {{
            fontSize: "1.2vmax", 
            marginLeft: "2vmax",
            cursor: "pointer", 
            color: "grey" }}>Disclaimer</h1>
        </div>
    )
}
export function MenuSticky(props)
{

    return(
        <div style = {{position: "fixed", display: 'flex', flexDirection: "column", zIndex: 50, marginLeft: "1vmax"}}>
            <Link to = "/" style = {{textDecoration: "none"}}>
                <h1 className = "header" style = {{
                fontSize: "1.2vmax",
                cursor: "pointer", 
                color: "grey"}}
                >About</h1>
            </Link>
            <Link to = "/get" style = {{textDecoration: "none"}}>
                <h1 className = "header" style = {{
                fontSize: "1.2vmax", 
                marginTop: "-.25vmax",
                cursor: "pointer", 
                color: "yellow"}}>HoneyBadger</h1>
            </Link>
            <Link to = "/get" style = {{textDecoration: "none"}}>
                <h1 className = "header" style = {{
                fontSize: "1.2vmax", 
                marginTop: "-.25vmax",
                cursor: "pointer", 
                color: "grey"}}>Instance Market</h1>
            </Link>

            <h1 className = "header" style = {{
            fontSize: "1.2vmax", 
            cursor: "pointer", 
            marginTop: "-.25vmax",
            color: "grey"}}>Join Telegram</h1>

            <h1 className = "header" style = {{
            fontSize: "1.2vmax", 
            marginTop: "-.25vmax",
            cursor: "pointer", 
            color: "grey"}}>Whitepaper</h1>

            <h1 className = "header" style = {{
            fontSize: "1.2vmax", 
            cursor: "pointer", 
            marginTop: "-.25vmax",
            color: "grey"}}>Disclaimer</h1>
        </div>
    )
}

