import { PageWrapper } from "../PageManager";
import '../../../../css/TextStyles.css'

import { BasicHeading, BasicText, BigHeading } from "../styledComponents";
import gsap from "gsap";
import ex from '../../../../assets/example.png'
import chart from '../../../../assets/chart.png'
import prob1 from '../../../../assets/prob1.png'
import indexeddata from '../../../../assets/indexed2.png'
import ssd from '../../../../assets/storageCentricDesign.png'
import ssd2 from '../../../../assets/ssdproblem.png'


import { useEffect, useState } from "react";


export default function Page1_1(props)
{


    useEffect(() =>
    {
        gsap.to(".t", {width: (props.docked == false) ? "50vmax" : "65vmax", opacity: 1})
    }, [props.docked])

    return(
        <PageWrapper docked = {props.docked == true ? "true" : "false"}>
            <BigHeading style = {{marginTop: "5vmax"}} className = {"inter" + " " + "fr" + " " + "t"}>Build Powerful Smart Contract Systems With Less Effort</BigHeading>
            <BasicText style = {{marginTop: "-1vmax", textAlign: "left", fontSize: "1.2vmax"}} className = {"opensans" + " " + "t"}>
            <b>
            HoneyBadger is a full-service, universally-applicable Solidity storage solution 
            that makes smart contract systems more capable and significantly easier to build.
            Introduce boundless scalability, top-notch security,
            and minimized gas costs to your project, 
            all while significantly reducing the effort, resources, and time needed to reach production.
            </b>

            </BasicText>

            <img src = {ex} alt = "basic honeybadger example" style = {{width: "50vmax", marginTop: "6vmax"}}/>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Scalability is Alien to Smart Contracts Systems, But Critical for Success on the Blockchain
            </BasicHeading>

            <BasicText style = {{marginTop: "0vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                When smart contract code is deployed to the blockchain, <b>it cannot be modified</b>. 
                As a result, critical capabilities -- such as the ability to address vulnerable code -- must be introduced through manual 
                effort.  This extra work bloats the development process and produces results of varying 
                quality.  Compounding the issue, comprehensive scalability solutions rapidly grow in scope and complexity
                as seemingly-basic features are added, imposing a considerable strain on the development and testing processes.
                Simple proxy-delegate scalability models are easy to build, but impose restrictions and 
                structural inefficiencies.  

                <br/><br/>

                Conversely, opting to ignore the issue makes it difficult to stay relevant in
                the rapidly-evolving blockchain space.  It's a dilemma: there are considerable drawbacks 
                associated with all available options. <b>But what if developers could avoid this burdensome process, 
                while ending up with a more cohesive and capable product?</b>  This is where HoneyBadger comes in, allowing
                 developers to rapidly deploy their own instance 
                of a pre-built, highly-optimized, universally-applicable, and fully-featured solution.  

                <br/><br/>


            </BasicText>

            <img src = {chart} alt = "HoneyBadger benefits chart" style = {{width: "50vmax"}}/>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Exploring the Dilemma
            </BasicHeading>

            <BasicText className = {"opensans" + " " + "t"}>
                
                Scalability solutions are <b>peripheral infrastructure. </b> These 
                systems play a critical supporting role, but they are not the central 
                focus of the overlying project.  Hence, any resources allocated towards building 
                scalability support will be at the expense of refining central objectives.  

                <br/><br/>

                <b>Example: &nbsp;</b>Let's consider what it takes to implement a single feature that HoneyBadger offers: 
                the ability to extend mapped datasets in a post-deployment environment.  
                Base Solidity offers the mapping-of-struct pattern, but this is constrained 
                only to what is pre-defined within that struct, (a struct is a data interface).  
                Developers can't just plug this in: they need to create their own low-level mapping-of-struct 
                abstraction and modify it to support live extension.  This requires a strong understanding of 
                many niche topics, including assembly programming, bit operations, and storage space management. 
                The cost of testing and optimization greatly expands costs, rendering it even more difficult 
                to achieve this capability in a production system.

                <br/><br/>

                <b>Result: &nbsp;</b>  Imagine a project that chooses not to build this complex, yet 
                extremely useful feature.  They are free to allocate more resources to their main 
                objectives, allowing them to reach production sooner and with less effort.

                    The cost of this omission only becomes apparent down the line: 
                since they are locked into the system they built up-front, they can no longer introduce 
                hot insertion at all.  To mirror this capability, they need to deploy another contract, 
                define the desired interface members,
                then connect this new contract to their existing system using the 
                integration tools they chose to build up front. Effectively, a single data structure (ie; user data) 
                would be spread between two contracts.
                This is inefficient at best, and impossible in the case that the relevant integration 
                capabilities are absent in their production system.  If capabilities are not 
                included on launch day, they may not be able to be mirrored in production.  If they can be 
                mirrored, it will likely be significantly less efficient than a dedicated solution
                built with foresight.

                <br/><br/>

                <b>Key Point: &nbsp;</b> As we have explained, the rigidity of deployed systems means that 
                 developers must pre-emptively determine how much scalability support they can feasibly introduce,
                 and the cost of building those features compounds rapidly. 
                Correspondingly, present-day production systems vary in their response to this issue:
                ranging from full renunciation of administrative control, (thereby removing any pressure 
                or expectation to build upon the deployment-day system) to state-of-the-art dedicated 
                solutions.  <b>However, all of these projects have one thing in common: they would be better off 
                with more options than fewer options, and even better off if they could have all of those options 
                without needing to build or test them.  </b>

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Clearly Discerning the Souce of the Problem
            </BasicHeading>

            <img src = {prob1} alt = "re-deploying smart contracts" style = {{width: "35vmax"}}/>

            <BasicText className = {"opensans" + " " + "t"}>

                We know that smart contracts are not natively scalable because they are 
                immutable after deployment.  But one could argue, "this issue can obviously be avoided in many 
                cases by simply re-deploying 
                a contract and connecting it back to the rest of the system, so what's all 
                of this fuss about?"  To fully understand the problem, we need to dive deeper 
                into its implications and causes.  


            </BasicText>

            <img src = {indexeddata} alt = "describing indexed data" style = {{width: "35vmax"}}/>

            <BasicText className = {"opensans" + " " + "t"}>

                As the previous passage mentioned, we can often avoid the scalability 
                problem by deploying an updated version of a deployed smart contract, 
                then simply connecting the new version to the rest of the system.  However, 
                this approach is neither as widely-applicable or safe as it first appears.

                <br/><br/>
                Let's consider this seemingly-logical solution under a new lens: what happens when 
                the initially-deployed contract stores data?  Recall the 
                mapping-of-struct example from earlier.  If we want to store user data, 
                we are going to first define an <i>interface </i> that denotes the 
                fields that characterize a unique user.  Copies of the interface are
                populated for each user and stored in distinct locations within the 
                storage space of the overlying smart contract.

                <br/><br/>

                Every smart contract occupies its own segregated storage space on the blockchain.  When 
                indexed data is stored, it can go to any of 256<span className = "superscript">256</span> &nbsp;
                available storage slots.  Mappings create a cryptographic hash based on the key, (ie; user index) 
                that we use to denote the storage location for an individual entry.  In practice, this means 
                that mapped data -- such as user data -- is dispersed all throughout the storage space, and 
                can only be located using unique keys.  We can't simply 
                parse through each key and transfer its associated data to a new contract instance, as the  
                gas costs would be astronomical: <i>n</i> * (multiple sloads, context swap, multiple fresh sstores).  
                Hence, a data migration will require that users can access 
                all of their data, as it will be a user-driven event.  Critically, this means that our entire 
                community will have to PAY to maintain their progress, which is not a great way to inspire 
                confidence or goodwill.  

                <br/><br/>

                In terms of code complexity, a migration event is not particularly complex, especially given 
                that we can define that logic without restriction in our new contract instance.  Instead, it is 
                the associated user-borne cost, coupled with the threat of lost resources that will do the most damage.  
                Given this information, we can concretely claim that the main source of the scalability problem is not 
                necessarily the fact that smart contract code cannot be modified after deployment.  After all, it is 
                completely true that we can deploy an updated version and connect it to the rest of the system.  Instead, 
                the issue arises when that contract stores mass data, which can only feasibly be transferred 
                through a user-driven migration event.   

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Storage-Centric Design
            </BasicHeading>

            <BasicText className = {"opensans" + " " + "t"}>

                We know that smart contracts contain both logic and data, and that the scalability 
                problem assumes its full form when contracts store mapped data.  This begs the question: 
                can we design our smart contract systems so that our logic contracts don't have 
                to store mapped data, (such as user data) at all?

            </BasicText>

            <img src = {ssd} alt = "storage-centric design diagram" style = {{width: "35vmax", marginTop: "3vmax"}}/>

            <BasicText className = {"opensans" + " " + "t"}>

                In fact, this type of smart contract system is completely viable: we can 
                create pure logic contracts that interact with specialized storage contracts, 
                allowing us to update the capabilities of our system with minimal restrictions.   

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                The Drawback of Storage-Centric Design
            </BasicHeading>

            <img src = {ssd2} alt = "storage-centric design diagram" style = {{width: "35vmax", marginTop: "3vmax"}}/>

            <BasicText className = {"opensans" + " " + "t"}>

                After a moment of reflection, the key drawback of this design pattern becomes obvious: 
                while our logical system is now fluid, our storage system is burdened by the mapped 
                data that it contains.  Instead of fully solving the problem, we have shifted it 
                to a new domain. Further compounding the issue, the storage system is 
                central to our entire ecosystem: if it is inefficient or lacking in terms of 
                capability, that flaw will propagate through our entire system, and it won't be 
                an easy fix. Furthermore, what security guarantees can we offer users?  After all, 
                the storage system can only delegate permissions to updated logic contracts if somebody 
                has administrative control.  This means that integrity of user 
                data is hinged on the unmetered discretion of an unseen third party, and our entire 
                smart contract system can be fundamentally altered at any time.  <i>Clearly, we have 
                overshot the mark</i>.  Our current solution remains burdened by the same problem that we had 
                before, while resolutely crushing many of the key benefits that the smart 
                contract medium offers, such as the potential for concretely-guaranteed integrity.
                
                <br/><br/> 

                After taking an even closer look at the scalability problem, we have 
                found ourselves with an even more grim set of parameters: the most powerful  
                solution introduces significant challenges of its own.  However, 
                there is a beacon of hope: if we can address 
                the issues surrounding storage-centric design, (otherwise known as 
                the "eternal" storage pattern) we could theoretically create a 
                very powerful solution that provides our contract system with a 
                significant competitive advantage.  Let's outline how 
                this could be achieved in practice.

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "1vmax", fontWeight: "bold", fontSize: "1vmax"}}>
                Addressing the Rigidty of the Storage Layer
            </BasicHeading>
            
            <BasicText className = {"opensans" + " " + "t"}>

                We know that our storage system will not be able to be replaced after 
                it has been populated with data.  Hence, it is important that it is built 
                with a set of features that can withstand the test of time.  While 
                projects vary in terms of their scope and use case, they share one 
                common characteristic: they would be better-served with more capabilities 
                than fewer.  Hence, our storage system should lean towards being 
                <i> excessively </i> equipped.  This is further bolstered by the lack of 
                real consequences to building a feature-rich system: <b>administrators will 
                pay a higher deployment cost once -- as opposed to a barebones model --
                but derive permanent and consequential benefits from that decision. 
                 </b>  Furthermore, this cost is likely to be offset by the ease of 
                 development that our system will provide in a post-deployment setting.
                 
                <br/><br/>

                Another factor to consider is that we don't want to keep rebuilding these 
                complex systems to handle each set of mapped data.  If we end up with a complex 
                web of connected contracts, our system will be similiar in structure to 
                more traditional systems: we want to do better.  This point is particularly salient 
                to the advanced developers presently reading this, thinking: "I still don't see the 
                problem with a loose proxy-delegate solution, it doesn't impose any clear restrictions, and it's easy 
                to implement."  In fact, proxy-delegate <i>does</i> impose clear restrictions, and it <i>does</i> 
                decrease structural efficiency.  It imposes context swaps, which are particularly costly in the 
                case of contract-to-contract communication.  Furthermore, it promotes a spiderweb pattern: due to the 
                native lack of storage space compatitibility with new contracts, <i>each logic contract needs its own 
                storage contract: we can't just have a single storage contract.</i>  Additionally, this loose 
                proxy-delegate pattern, while being arguably the best solution presently available, directly reduces 
                system integrity by providing unmetered low-level control over system structure.
                
                <br/><br/>
                Our system should be 
                designed to handle multiple independent storage spaces, and they should be 
                constructable after deployment.  Interfaces for mapped data 
                (refer to the struct example above) should also be extendable.  Correspondingly,  
                we will need to create our own struct abstraction, and it would be best-suited 
                with full type support.

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "1vmax", fontWeight: "bold", fontSize: "1vmax"}}>
                Imposing Concrete Integrity
            </BasicHeading>

            <BasicText className = {"opensans" + " " + "t"}>

                For our storage system to function at all, the ability to 
                retrieve and modify data must be provided.  Otherwise, our system 
                would be entirely useless.  Furthermore, we need to be able to delegate 
                these permissions to new contract instances to accomodate updates 
                to our logical system.  This means that enhanced permissions need 
                to exist on a user level, there is no possible alternative.

                <br/><br/>

                How can we balance these conflicting needs?  The answer is by leveraging 
                user governance.  Instead of providing administrators with unchecked 
                powers, we can allow them to make proposals to change the structure of 
                the system (by delegating and removing permissions),
                while only allowing smart contracts to modify and retrieve data.  
                If these contracts are open-sourced, then full transparency and integrity is 
                achieved.  In fact, our storage layer only needs a simple permission 
                system to provide a full guarantee of security.  There is no need for leeway: 
                an entity either carries the requisite permissions or has absolutely 
                no ability to interact with the system.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "1vmax", fontWeight: "bold", fontSize: "1vmax"}}>
                Optimizing the System
            </BasicHeading>

            <BasicText className = {"opensans" + " " + "t"}>

                Another critical element of our theoretical solution is the optimization of its 
                component functions.  Recall that the storage layer is central to the design of our 
                smart contract system: there is one storage contract, but there can be innumerable 
                logic contracts.  Most of the functions in our logic contracts will involve modifying or retrieving data, 
                which means that poor efficiency of our storage operations will propagate through our entire system.  
                Hence, it is important that our end product is optimized to the greatest possible degree, 
                especially considering that we will not be able to feasibly re-deploy our storage 
                system once it is populated with data.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "2vmax", fontWeight: "bold"}}>
                Bringing It All Together
            </BasicHeading>
            <BasicText className = {"opensans" + " " + "t"}>

                As a whole, our proposed solution would be an incredibly useful system, and 
                we are yet to examine its features in depth.  Unfortunately, building this system 
                would be a massive undertaking: we have to build numerous complex 
                and low-level features, while also making them as efficient as possible.  Naturally, 
                this will be extremely expensive, both in terms of time and capital.  In addition to 
                development and optimization, the system will need to be thoroughly tested, as 
                any vulnerabilities will cripple our entire contract ecosystem.  

                <br/><br/>

                Recall that storage systems are <i>peripheral infrastructure. </i> Our proposed 
                solution will not bring about the overarching goals of a project, but rather 
                bolster those features once they are built, while opening the door to unfettered 
                innovation.  This is why most projects get by 
                with incomplete (or simply without) scalability solutions: the scope of 
                a truly capable solution is extremely daunting, and simply unattainable for 
                projects with limited resources.  However, it is hard to deny that 
                most projects would greatly benefit from these additional capabilities.  Furthermore, 
                our proposed solution is highly-applicable: any project that stores data 
                is a viable candidate.  Hence, the absence of a complete scalability solution is not 
                indicative of a lack of utility, but rather of the huge added burden that 
                the development process brings.  Clearly, the best solution would be to 
                use a pre-built, highly-optimized system, allowing developers to leverage 
                the enhanced post-deployment capabilities without paying the huge cost of
                building their own implementation.

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Focus on your big idea - HoneyBadger can handle the rest.
            </BasicHeading>

            <BasicText className = {"opensans" + " " + "t"}>

                At this point, it may seem like we have covered every topic 
                but HoneyBadger itself.  However, the theoretical solution we described 
                above is actually analogous to the HoneyBadger system as it is.  Simply put, HoneyBadger 
                is a state-of-the-art storage solution designed to comprehensively put 
                the issue of scalability to rest.  Our vision is to revolutionize 
                the smart contract development process, allowing developers to 
                immediately leverage advanced peripheral architecture without 
                paying the hefty cost of building it themselves.  Additionally, 
                HoneyBadger is applicable to any smart contract system that stores data, 
                and is able to manage thousands of segregated storage spaces, which 
                can be freely created and extended after deployment.  That means that a single 
                HoneyBadger system can fully support a growing contract ecosystem, 
                allowing developers to create streamlined pure-logic contracts 
                empowered by the extreme efficiency of HoneyBadger's operations.  

                <br/><br/>

                In addition to providing a massive suite of capabilities optimized 
                to approach the possible lower limit of computational cost, 
                HoneyBadger fully maintains both logical and data integrity.  
                Developers can choose between numerous specialized modules 
                to construct a system variant that perfectly suits their use case.  After all, 
                not every project requires comprehensive security measures. However, some, 
                some -- like DAOs -- require extreme system integrity.  In truth, 
                scalability is a nuanced issue, and a rigid solution could 
                never be perfectly-suited for every use case.  By offering a suite of 
                specialized modules, we greatly extend the applicability 
                of the system without making any concessions.  Critically, the base 
                HoneyBadger system takes a hands-off approach, providing powerful 
                permissioning support, but not imposing any true restrictions on 
                developer autonomy.  Such restrictions are voluntarily imposed 
                to match the needs of a given project. 

                <br/><br/>

                While HoneyBadger is extremely complex under the hood, it is 
                easy to obtain, deploy, configure, and use.  We employ a simple 
                NFT-based licensing model that makes the system instantly 
                available, while providing unique incentives for 
                community-based growth and peer-to-peer engagement.  The system 
                can be easily integrated with existing code structures and 
                tested before deployment using "dumb models," which are 
                un-optimized, open-source implementations built with simple high-level 
                Solidity.  They mimic the functionality of the real system to facilitate 
                effective testing.  We encourage developers to read the docs fully before 
                using the system in production.  While the system is already 
                highly-optimized, our recommended best practices make it easy to 
                leverage and understand advanced features that bring gas costs 
                to an absolute minimum.  By effectively leveraging features 
                such as bit packing and batch retrieval/storage, developers can 
                optimize their system far beyond what is generally accessible. 
                Beyond making development easier, we want to empower smart 
                contract developers with the tools they need to build systems 
                designed to not only withstand, but conquer the test of time.  In a rapidly-evolving 
                market, it makes little sense for production systems to be 
                hindered by inefficient design and poor scalability.  

                <br/><br/>

            </BasicText>

        </PageWrapper>
    )
}


