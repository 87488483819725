/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { DoubleSide, MeshBasicMaterial } from 'three'
import { useStoryBrandSectionContext } from '../LowerScrollSection/StoryBrandSection/StoryBrandSectionWrapper'

export function NeoGlasses(props) 
{
  const { nodes, materials } = useGLTF('/neo.glb')
  const { neoGlassesRef } = useStoryBrandSectionContext();

    const mat2 = new MeshBasicMaterial({color: 'white', side: DoubleSide})
    const mat3 = new MeshBasicMaterial({color: 'black', side: DoubleSide})

  return (
    <group ref = {neoGlassesRef} {...props} dispose={null} name = "NeoGlassesController">
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.eyeglasses.geometry}
        material={mat3}
        position={[1.348, 2.56, 1.074]}
        rotation={[0, -0.12, -Math.PI / 2]}
        scale={[0.194, 2.217, -0.361]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.nose_pad.geometry}
        material={mat2}
        position={[1.446, 3.054, 0.881]}
        rotation={[-0.398, -0.12, -1.764]}
        scale={[0.031, 0.356, -0.058]}
      />
    </group>
  )
}

useGLTF.preload('/neo.glb')

