import {animated, useSpring} from 'react-spring';
import '../../../css/TextStyles.css'
import { useState } from 'react';
import arrow2 from '../../../assets/arrow2.png'
import dev from '../../../assets/developer.png'
import vid from '../../../assets/videoguides.png'
import basics from '../../../assets/basics.png'
import wp from '../../../assets/whitepaper.png'
import { AspectManager2, HFI } from '../../Helpers/ClipManager';
import logo from '../../../assets/logo512.png'

export default function SidebarItems(props)
{
    const [hovered, setHovered] = useState(0)
    const [extended1, setExtended1] = useState(1);
    const [extended2, setExtended2] = useState(0);
    const [extended3, setExtended3] = useState(0);


    return(
        <div style = {{
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: `${(props.hits * 2) + 10}vmax`
        }}>

            <div 
            onPointerOver={() => {
                setHovered(1)
            }}
            onPointerOut={() => {setHovered(0)}}
            onClick={() => {setExtended1((extended1) === 1 ? 0 : 1)}}
            style = {{background: (hovered == 1) ? "#606060" : "#303030", width: "20vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', alignItems: 'center', cursor: "pointer"}}>
                <p className = "opensans" style = {{color: "white"}}>Fundamentals</p>
                <img src = {arrow2} alt = "arrow" style = {{rotate: (extended1 == 1) ? "90deg":"0deg", width: "1vmax", height: "1vmax", position: "absolute", marginLeft: "15vmax"}}/>
                <img src = {basics} alt = "arrow" style = {{width: "2vmax", height: "2vmax", position: "absolute", marginLeft: "-15vmax"}}/>
            </div>

            <div 
            onClick={() => {props.setPage(1.1)
            props.setHeight(1200)}}
            onPointerOver={() => {setHovered(1.1)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 1.1) ? "#606060" : (props.page == 1.1) ? "#4d5259": "#505050", width: (extended1 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: "1px solid #202020"}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended1 === 1) ? "1vmax" : "0vmax"}}><i>Contextualizing HoneyBadger</i></p>
            </div>

            <div 
            onClick={() => {
                props.setPage(1.2)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(1.2)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 1.2) ? "#606060" : (props.page == 1.2) ? "#4d5259": "#505050", width: (extended1 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended1 === 1) ? "1px solid #202020" : 0}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended1 === 1) ? "1vmax" : "0vmax"}}><i>
                Contemporary Scalability Solutions</i></p>
            </div>

            <div 
            onPointerOver={() => {setHovered(2)}}
            onPointerOut={() => {setHovered(0)}}
            onClick={() => {setExtended2((extended2) === 1 ? 0 : 1)}}
            style = {{background: (hovered == 2) ? "#606060" : "#303030", width: "20vmax", justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center", textAlign: 'center', cursor: "pointer", borderBottom: "1px solid #202020"}}>
                <p className = "opensans" style = {{color: "white"}}>HoneyBadger</p>
                <img src = {arrow2} alt = "arrow" style = {{rotate: (extended2 == 1) ? "90deg":"0deg", width: "1vmax", height: "1vmax", position: "absolute", marginLeft: "15vmax"}}/>
                <img src = {logo} alt = "arrow" style = {{width: "2vmax", height: "2vmax", position: "absolute", marginLeft: "-15vmax"}}/>
            </div>
            <div 
            onClick={() => {
                props.setPage(2.1)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(2.1)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 2.1) ? "#606060" : (props.page == 2.1) ? "#4d5259": "#505050", width: (extended2 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended2 === 1) ? "1px solid #202020" : 0 }}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended2 === 1) ? "1vmax" : "0vmax"}}><i>Abstract</i></p>
            </div>

            <div 
            onClick={() => {
                props.setPage(2.2)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(2.2)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 2.2) ? "#606060" : (props.page == 2.2) ? "#4d5259": "#505050", width: (extended2 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended2 === 1) ? "1px solid #202020" : 0}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended2 === 1) ? "1vmax" : "0vmax"}}><i>Core Tenets</i></p>
            </div>

            <div 
            onClick={() => {
                props.setPage(2.3)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(2.3)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 2.3) ? "#606060" : (props.page == 2.3) ? "#4d5259": "#505050", width: (extended2 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended2 === 1) ? "1px solid #202020" : 0}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended2 === 1) ? "1vmax" : "0vmax"}}><i>Practical Example</i></p>
            </div>

            <div 
            onClick={() => {
                props.setPage(2.4)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(2.4)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 2.4) ? "#606060" : (props.page == 2.4) ? "#4d5259": "#505050", width: (extended2 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended2 === 1) ? "1px solid #202020" : 0}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended2 === 1) ? "1vmax" : "0vmax"}}><i>Technical Deep-Dive</i></p>
            </div>

            <div 
            onClick={() => {
                props.setPage(2.5)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(2.5)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 2.5) ? "#606060" : (props.page == 2.5) ? "#4d5259": "#505050", width: (extended2 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended2 === 1) ? "1px solid #202020" : 0}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended2 === 1) ? "1vmax" : "0vmax"}}><i>Low-Level Architecture</i></p>
            </div>

            <div 
            onClick={() => {
                props.setPage(2.6)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(2.6)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 2.6) ? "#606060" : (props.page == 2.6) ? "#4d5259": "#505050", width: (extended2 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended2 === 1) ? "1px solid #202020" : 0}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended2 === 1) ? "1vmax" : "0vmax"}}><i>Deploying HoneyBadger</i></p>
            </div>

            <div 
            onClick={() => {
                props.setPage(2.7)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(2.7)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 2.7) ? "#606060" : (props.page == 2.7) ? "#4d5259": "#505050", width: (extended2 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended2 === 1) ? "1px solid #202020" : 0}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended2 === 1) ? "1vmax" : "0vmax"}}><i>Using HoneyBadger</i></p>
            </div>

            <div 
            onClick={() => {
                props.setPage(2.8)
                props.setHeight(1200)
                }}
            
            onPointerOver={() => {setHovered(2.8)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 2.8) ? "#606060" : (props.page == 2.8) ? "#4d5259": "#505050", width: (extended2 === 1) ? "20vmax":"0vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', cursor: "pointer", borderBottom: (extended2 === 1) ? "1px solid #202020" : 0}}>
                <p className = "opensans" style = {{color: "white", fontSize: (extended2 === 1) ? "1vmax" : "0vmax"}}><i>Specialized Implementations</i></p>
            </div>

            <div 
            onPointerOver={() => {setHovered(3)}}
            onPointerOut={() => {setHovered(0)}}
            onClick={() => {setExtended3((extended3) === 1 ? 0 : 1)}}
            style = {{background: (hovered == 3) ? "#606060" : "#303030", width: "20vmax", justifyContent: "center", display: "flex", flexDirection: "row", alignItems: "center", textAlign: 'center', cursor: "pointer", borderBottom: "1px solid #202020"}}>
                <p className = "opensans" style = {{color: "white"}}>Functions</p>
                <img src = {arrow2} alt = "arrow" style = {{rotate: (extended3 == 1) ? "90deg":"0deg", width: "1vmax", height: "1vmax", position: "absolute", marginLeft: "15vmax"}}/>
                <img src = {dev} alt = "arrow" style = {{width: "2vmax", height: "2vmax", position: "absolute", marginLeft: "-15vmax"}}/>
            </div>
            <div 
            onPointerOver={() => {setHovered(4)}}
            onPointerOut={() => {setHovered(0)}}
            style = {{background: (hovered == 4) ? "#606060" : "#303030", width: "20vmax", justifyContent: "center", display: "flex", flexDirection: "row", textAlign: 'center', alignItems: "center", cursor: "pointer", borderBottom: "1px solid #202020"}}>
                <p className = "opensans" style = {{color: "white"}}>Video Tutorials</p>
                <img src = {arrow2} alt = "arrow" style = {{width: "1vmax", height: "1vmax", position: "absolute", marginLeft: "15vmax"}}/>
                <img src = {vid} alt = "arrow" style = {{width: "2vmax", height: "2vmax", position: "absolute", marginLeft: "-15vmax"}}/>
            </div>

        </div>
    )
}


