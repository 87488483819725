export const fragmentShader = 
`
varying vec2 vUv;
varying vec3 vNormal;
uniform float time;
uniform float u_hovered;
uniform float u_back;
uniform float u_opacity;
uniform float u_transitionTime;

vec3 effect(float diff)
{
    float saturation = .7;

    return( vec3(
        abs(sin(diff * 2. + time * 2.5)) * saturation,
        sin(vUv.y + time) * saturation,
        cos(vUv.y + time * 5. + vUv.x + time * 1. / vNormal.x) * saturation));
}

void main() 
{

    float FACTOR = .5;
    float depth = abs(dot(normalize(vNormal), vec3(0, 0, 1)));

    vec3 color;
    if (u_back == 0.) 
    {
        color = mix(vec3(.1), vec3(.8), depth) * FACTOR;
    } else 
    {
        color = mix(vec3(.1), vec3(.9), depth - .1);
    }

    if (u_hovered == 0.) 
    {
        if(u_transitionTime > 0.)
        {
            float diff = dot(vec3(1.), vNormal);
            vec3 hover_effect = effect(diff);
            hover_effect = mix(color, hover_effect, u_transitionTime);
            gl_FragColor = vec4(hover_effect, u_opacity);
            return;
        }
        gl_FragColor = vec4(color, u_opacity);
        return;
    } 

    // Original animation when hovered is true
    float diff = dot(vec3(1.), vNormal);

    vec3 hover_effect = effect(diff);
    if(u_transitionTime < 1.)
    {
        hover_effect = mix(color, hover_effect, u_transitionTime);
    }
    gl_FragColor = vec4(hover_effect, u_opacity);
    
}
`

export const fragmentShaderDefault = 
`
varying vec2 vUv;
varying vec3 vNormal;
uniform float time;

uniform float u_back;
uniform float u_opacity;
uniform float u_transitionTime;

vec3 effect(float diff)
{
    float saturation = .7;

    return( vec3(
        abs(sin(diff * 2. + time * 2.5)) * saturation,
        sin(vUv.y + time) * saturation,
        cos(vUv.y + time * 5. + vUv.x + time * 1. / vNormal.x) * saturation));
}

void main() 
{

    float FACTOR = .5;
    float depth = abs(dot(normalize(vNormal), vec3(0, 0, 1)));

    vec3 color;
    if (u_back == 0.) 
    {
        color = mix(vec3(.1), vec3(.8), depth) * FACTOR;
    } else 
    {
        color = mix(vec3(.1), vec3(.9), depth - .1);
    }

    if (u_hovered == 0.) 
    {
        if(u_transitionTime > 0.)
        {
            float diff = dot(vec3(1.), vNormal);
            vec3 hover_effect = effect(diff);
            hover_effect = mix(color, hover_effect, u_transitionTime);
            gl_FragColor = vec4(hover_effect, u_opacity);
            return;
        }
        gl_FragColor = vec4(color, u_opacity);
        return;
    } 

    // Original animation when hovered is true
    float diff = dot(vec3(1.), vNormal);

    vec3 hover_effect = effect(diff);
    if(u_transitionTime < 1.)
    {
        hover_effect = mix(color, hover_effect, u_transitionTime);
    }
    gl_FragColor = vec4(hover_effect, u_opacity);
    
}
`

export const fragmentShaderPhase1 = 
`
varying vec2 vUv;
varying vec3 vNormal;
uniform float time;
uniform float u_opacity;
uniform float u_back;
uniform float u_hovered;


vec3 effect(float diff)
{
    return( vec3(
        abs(sin(diff * 10. + time * 2.)), 
        abs(sin(vUv.y + time) * .4),
        abs(cos(vUv.y + time * 5. + vUv.x + time * 1. / vNormal.x) * .4)));
}

void main() 
{
 
    if(u_hovered > 0.)
    {
        float diff = dot(vec3(1.), vNormal);
        vec3 hover_effect = effect(diff);
    
        //float adj_opacity = sin(1. + u_opacity * time * vUv.x * vUv.y * 1.2);
        gl_FragColor = vec4(hover_effect, u_opacity);
        return;
    }

    float FACTOR = .5;
    float depth = abs(dot(normalize(vNormal), vec3(0, 0, 1)));

    vec3 color;
    if (u_back == 0.) 
    {
        color = mix(vec3(.1), vec3(.8), depth) * FACTOR;
    } else 
    {
        color = mix(vec3(.1), vec3(.9), depth + .3);
    }

    gl_FragColor = vec4(color, u_opacity);
}
`

export const fragmentShader2 = 
`
    varying vec2 vUv;
    varying vec3 vNormal;
    uniform float time;
    uniform float u_opacity;
    uniform float u_back;
    uniform float u_hovered;

    void main()
    {
        if(u_hovered == 0.)
        {
            if(u_back == 0.)
            {
                gl_FragColor = vec4(vec3(.1), u_opacity);
                return;
            }
            else
            {
                gl_FragColor = vec4(vec3(1), u_opacity);
                return;
            }
        }

        float diff = dot(vec3(1.), vNormal);
        gl_FragColor = vec4(abs(sin(diff * 10. + time * 2.5)) * .4, sin(vUv.y + time) * .4, cos(vUv.y + time * 5. + vUv.x + time * 1. / vNormal.x) * .4, u_opacity);
    }
`


export const fragmentShaderFrac = 
`
uniform float time;
varying vec2 vUv;

void main()
{
    vec2 normalizedCoord;

    if(time == 0.)
    {
        normalizedCoord = vUv;
    }
    else
    {
        float angle = (time * .075) - 8.;
        normalizedCoord = vUv * 2. - 1.;
        float shearFactor = .5 + sin(time * .5) * .1;

        float i = 0.;
        
        for(;i < 32.;)
        {
            normalizedCoord = abs(normalizedCoord);
            normalizedCoord -= .25 + (sin(time) * .0001);
            normalizedCoord *= 1.1;
            normalizedCoord.x += shearFactor * normalizedCoord.y;

            normalizedCoord *= mat2(
                cos(angle), -sin(angle),
                sin(angle), cos(angle)
            );

            normalizedCoord *= .8;

            i++;
        }
    }


    gl_FragColor = vec4(vec3(length(normalizedCoord * vec2(2.))), 1.);
}

`

export const vertexShader = 
`
    varying vec2 vUv;
    varying vec3 vPosition;
    varying vec3 vNormal;
    uniform float time;


    #include <common>
    #include <skinning_pars_vertex>

    void main()
    {
        #include <skinbase_vertex>
        #include <begin_vertex>
        #include <beginnormal_vertex>
        #include <defaultnormal_vertex>
        #include <skinning_vertex>
        #include <project_vertex>

        vUv = uv;
        vNormal = normal;

        vNormal = normalize(normalMatrix * normal);
        vPosition = vec3(modelViewMatrix * vec4(position, 1.0));

        gl_Position = projectionMatrix * mvPosition;
        
    }
`