// App.js
import "./index.css";
import gsap from "gsap";
import SignIn from "./signIn";
import QuestSystem from "./QuestSystem";
import { CoolerMenu } from "./components/Text/Menu";
import React, { useEffect, useRef, useState } from "react";
import LowerScrollSection from "./components/LowerScrollSection/LowerScrollSection";


function App() 
{
  const sceneRef = useRef();
  const [sceneInView, setSceneInView] = useState();
  const [dimsChanged, setDimsChanged] = useState(false);
  const [user, setUser] = useState((false) ? "aba" : null);


  function handleSignIn(user)
  {
    setUser(user);
  }

  useEffect(() =>
  {
    if(!sceneRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        let intersecting = entry.isIntersecting;
        if(sceneInView !== intersecting)
        {
          setSceneInView(intersecting);
        }
      }, {threshold: .1}
    );
    observer.observe(sceneRef.current);

    return () => {
      if (sceneRef.current) {
          observer.unobserve(sceneRef.current);
      }
    }

  }, [sceneRef.current, sceneInView]);


  useEffect(() => 
  {
      if(!user) return;
      gsap.to(".noPreload", { visibility: "visible", opacity: 1 });

      const handleResize = () => {
        setDimsChanged((prev) => !prev);
      };

      window.addEventListener("resize", handleResize);

      console.log(window.innerWidth / window.innerHeight);

      return () => {
        window.removeEventListener("resize", handleResize);
        document.body.style.overflowY = "";
      };
  }, [dimsChanged, user]);


  if (!user) 
  {
    return <SignIn onSignIn={handleSignIn}/>;
  }
  
  return (
    <>
      <CoolerMenu currentPage="#i1" phase = {0}/>
      <div 
        ref = {sceneRef}
        className="noPreload" 
        style={{ height: "100vh" }}
      >
        {sceneInView && <QuestSystem />}
      </div>
      <LowerScrollSection />
    </>
  );
}



export default App;