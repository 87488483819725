import './joinCommunity.css'
import badgergif from '../../../assets/joincommunitygif.gif'
import { useEffect, useState } from 'react'

export default function JoinCommunity(props)
{
    const [playGif, setGif] = useState(false);
    const [hovered, setHovered] = useState(false)
    
    useEffect(() =>
    {
        setTimeout(() => 
        {
            if(window.innerWidth / window.innerHeight < 1)
            {
                return;
            }
            setGif(true);

            setTimeout(() =>
            {
                setGif(false)

            }, 15000)

        }, 30000)
    }, [])

    useEffect(() =>
    {
        if(props.hovered) setHovered(true);
        else setHovered(false)

    }, [props.hovered])

    if(!playGif) return null;

    return(
        <div className='JoinCommunityGIFContainer'>
            <img className = "JoinCommunityGIF" src = {badgergif} />
        </div>
    )
}