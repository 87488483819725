/*
    Uses my viewport as a benchmark to ensure responsiveness for all systems.
    718h 1536w
*/

export function ClipManagerWidth(value)
{
    let ratio = window.innerWidth / 1536;
    value *= ratio;
    
    console.log(value)

    return value;
}

export function ClipManagerHeight(value)
{
    let ratio = window.innerHeight / 718;
    value *= ratio;
    return value;
}

export function AspectManager(value)
{
    let ratio = (window.innerWidth / 1536) / (window.innerHeight / 718)
    value *= ratio

    return value;
}

export function HFI(value)
{
    let r = window.innerHeight / 718;

    value *= r;

    return `${value}vh`
}

export function AspectManager2(value)
{
    let ratio = (window.innerWidth / 1536) / (window.innerHeight / 718)
    value *= ratio

    return `${value}vmax`;
}

export function Variant6969(value)
{
    let w = window.innerWidth;
    let h = window.innerHeight;
    let v = 0;
    let ratio;

    let rw = w / 1536;
    let rh = h / 718;

    if(w > h)
    {
        ratio = rw / rh
    }
    else
    {
        ratio = rh / rw;
    }   

    value = ratio * value;
    return `${value}vmax`;
}