import { useSpring, animated } from "react-spring"
import Page1_1 from "./Page_1/Page1_1"
import Page2_2 from "./Page_2/Page2_2"
import Page2_3 from "./Page_2/Page2_3"
import Page2_4 from "./Page_2/Page2_4"
import Page1_2_NEW from "./Page_1/Page1_2NEW"

import Page2_1NEW from "./Page_2/Page2_2"
import Page2_1 from "./Page_2/Page2_1"
import Page2_5 from "./Page_2/Page2_5"
import { useState } from "react"




export default function PageManager(props)
{
    if(props.page === 0 || props.page === undefined)
    {
        return
    }

    if(props.page == 1.1)
    {
        return <Page1_1 docked = {props.docked} />
    }
    if(props.page == 1.2)
    {
        return <Page1_2_NEW docked = {props.docked} />
    }
    if(props.page == 2.1)
    {
        return <Page2_1 docked = {props.docked} />
    }
    
    if(props.page == 2.2)
    {
        return <Page2_2 docked = {props.docked} />
    }

    if(props.page == 2.3)
    {
        return <Page2_3 docked = {props.docked} />
    }
    if(props.page == 2.4)
    {
        return <Page2_4 docked = {props.docked} />
    }
    if(props.page == 2.5)
    {
        return <Page2_5 docked = {props.docked} />
    }
}

export const PageWrapper = (props) =>
{
    let d = props.docked == "true" ? true : false;

    const [loaded, setLoaded] = useState(false)

    const anim = useSpring({

        from: {
            width: "67vmax",
            background: "#151515", 
            zIndex: 50, 
            position: "absolute", 
            marginLeft: "26.75vmax", 
            marginTop: "6vmax", 
            border: "1px solid #505050",
            opacity: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
            textAlign: 'center',
            paddingBottom: "10vmax",
            color: "white"},
        to: {
            width: (d) ? "85.5vmax":"67vmax",  
            background: "#151515", 
            zIndex: 50, 
            position: "absolute", 
            marginLeft: (d) ? "10vmax" :"26.75vmax", 
            marginTop: "6vmax",
            border: "1px solid #505050",
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: 'center',
            alignItems: 'center',
            paddingBottom: "10vmax",
            borderRadius: "5px",
            color: "white"},
        config: {duration: 500},
        delay: 250
    });

    return(
        <>
            <animated.div style = {anim} {...props}>
                {props.children}
            </animated.div>
        </>
    )
}