import { PageWrapper } from "../PageManager";
import { BasicText, BasicHeading, BigHeading } from "../styledComponents";
import gsap from "gsap";
import { useEffect } from "react";
import fishing from '../../../../assets/fishing.png'

export default function Page2_3(props)
{

    useEffect(() =>
    {
        gsap.to(".t", {width: (props.docked == false) ? "50vmax" : "65vmax", opacity: 1})
    }, [props.docked])

    return(
        <PageWrapper docked = {props.docked == true ? "true" : "false"}>
            <BigHeading style = {{marginTop: "5vmax"}} className = {"inter" + " " + "fr" + " " + "t"}>
                Practical Example
            </BigHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                Imagine that a development team is trying to build a
                Solidity-based play-to-earn fishing game.  Users acquire upgradeable fishing rod NFTs, which allow them 
                to play the game on their frontend to earn fish NFTs, which they can trade with other users.  
                The development team knows that this simple concept will expand over time: they want to 
                introduce in-game currencies and additional items, which all need to be represented 
                in their contract system.  They also need to ensure that gas costs are as low as 
                possible to maximize user engagement.

                <br/><br/>

                This development team definitely needs scalability, so they consider their options. 
                They have four main contracts: a system state manager, game logic contract, 
                fishing rod contract, and fish contract.  The game logic, fishing rod, and 
                fish contracts all need to support interface-excluded upgrades and 
                ideally dataset extension, so they have to use external storage instead of 
                proxy-delegate.  Each of these systems has different demands and dataset types, 
                so they need to build four external storage systems.  However, they realize 
                that they can't extend their datasets after deployment if they use basic 
                high-level Solidity mapped structs, which will make it 
                cumbersome to add new attributes to the fishing rod and fish contracts.  They also 
                know that users will be concerned if the development team can 
                modify logic and data at their unmetered discretion, which means that they need
                to consider some type of governance-based control structure.  

                <br/><br/>

                In total, the team has to build four separate systems and ensure 
                that each conforms to a single governance solution for the sake of efficiency.  
                They also want to introduce extendable data structures, which means creating 
                their own extendable struct abstraction.  Then they realize that since they 
                can't leverage native Solidity types anymore, they need to implement 
                type support as well.  After all is said and done, months have passed 
                and the system is finally complete.  It is poorly-optimized, so they need 
                to invest more time to ensure that the system is usable.  Afterwards, 
                the system undergoes extensive testing, revealing that their complex 
                optimization techniques introduced several vulnerabilities.  This entire 
                process was extremely demanding, took away a huge amount of 
                resources from the actual fishing game, and STILL produced 
                a sub-optimal solution.

                <br/><br/>

                Now let's consider this process if they used HoneyBadger.  The team 
                decides upon the HoneyBadger model (more on this later) that they want to use,
                opting for the DAO model.  They want to manage multiple storage spaces within 
                the same system, so they choose the "long" variant, as opposed to the single-space 
                "default" variant.  HoneyBadgerDAO provides community governance and 
                only allows contracts to modify and retrieve data, requiring that 
                all privilege-bearing contracts open-source their code.  They acquire the code 
                for a "dumb model," an interface-conforming, unoptimized HoneyBadger implementation 
                that allows developers to test their system before acquiring a real license.  Then
                they build and test their system.  Once it's ready, they acquire a license and 
                deploy a real HoneyBadgerDAO instance.  They initialize their data structures 
                and link their deployed contract system. 

                <br/><br/>

                Using HoneyBadger saved the team a significant amount of time 
                and money, which they were able to invest back into the fishing game itself.  
                Additionallly, they ended up with an even more powerful system than they would have 
                otherwise had.  Notably, in addition to providing highly-optimized 
                functions, HoneyBadger can be skillfully employed to further maximize efficiency 
                through the use of simple design patterns.  The team greatly reduces running costs 
                by efficiently packing their datasets based on which values are often modified in close proximity, then 
                uses optimized batch storage and retrieval functions to minimize 
                expensive <i>sstore </i> and <i>sload </i> operations, further enhanced 
                by their mindfully-packed storage layout.  Using batch functions in this manner also 
                reduces context swaps, which add moderate gas costs.  The community 
                is skeptical of the system's security at first, but find that they are able to 
                view the entire permission structure of the system, and can also
                view the open-sourced implementations for every permissioned contract by invoking the <i>get_link</i> function.
                They also notice that no user-level addresses have any permissions, and see 
                that the system is independently verified by the official HoneyBadger team, 
                and passes the built-in <i>verify_integrity</i> function.  Together, 
                these factors provide concrete assurances as to the safety of their data, 
                satisfying the community.  Furthermore, the team managed to fulfill their true vision for the game, 
                able to invest all of their time and effort into its optimization and 
                user experience.  
            </BasicText>
        </PageWrapper>
    )
}