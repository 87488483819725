import { PageWrapper } from "../PageManager";
import { BasicText, BasicHeading, BigHeading } from "../styledComponents";
import gsap from "gsap";
import { useEffect } from "react";

export default function Page2_2(props)
{
    useEffect(() =>
    {
        gsap.to(".t", {width: (props.docked == false) ? "50vmax" : "65vmax", opacity: 1})
    }, [props.docked])

    return(
        <PageWrapper docked = {props.docked == true ? "true" : "false"}>

            <BigHeading style = {{marginTop: "5vmax"}} className = {"inter" + " " + "fr" + " " + "t"}>
                Core Tenets
            </BigHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left", fontSize: "1.2vmax"}} className = {"opensans" + " " + "t"}>

                This section provides a high-level overview of the HoneyBadger system by 
                outlining its key goals and characteristics.  After exploring this section, 
                readers will be better-prepared for later technical explanations 
                which build upon these foundational topics.  Note that this section was written under the assumption  
                that readers are already familiar with all of the preceeding documentation content.

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Scalability
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                The enduring, central vision behind the HoneyBadger system is to provide 
                a comprehensive solution to the wide-reaching issue of smart contract 
                scalability.  As we have mentioned, many scalability solutions are 
                specialized to provide specific desired capabilities that are immediately 
                relevant to the overlying project.  However, the full range of potential future
                 needs are difficult to comprehensively
                identify, and therefore to address adequately with foresight alone.  Hence, it is 
                safer to deploy with a wide range of capabilities than a few select options that seem immediately relevant.   

                <br/><br/>

                Developers 
                are faced with a difficult dilemma.  Firstly, they can pour a significant amount of time and capital 
                into implementing a feature-rich scalability solution at the immediate 
                expense of front-facing, central objectives, while likely extending the necessary 
                development period.  Alternatively, they can rush to market while neglecting 
                system scalability, but will be greatly hindered in their efforts for continued 
                innovation or to address vulnerabilities.  This problem is especially potent 
                in light of the fact (which we have explored in previous sections) that 
                it is almost always extremely difficult or completely infeasible to 
                introduce heightened scalability to a deployed system.

            </BasicText>

            
            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Concrete Security and System/Data Integrity
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                As we have outlined in the "Fundamentals" section, the immutability of deployed 
                smart contracts is actually a significant boon for the medium as a whole, 
                concretely preventing foul play so long as the system was not corrupted 
                from its inception.  Sure, developers can introduce external systems, but 
                they are constrained to the degree of low-level control provided by the 
                existing ecosystem: you can't turn a house into a plane by taping wings to it.  

                <br/><br/>

                Critically, scalability solutions almost always 
                come at the direct expense of this concrete guarantee of integrity, which is a key factor 
                behind the prevalence of full ownership renuciation, despite its obvious drawbacks.
                  If administrators can 
                modify the logical system, access and modify data at their unmetered 
                discretion, the guarantee of user safety is reduced to the honesty of those individuals.
                In fact, during our developer-feedback phase, this was one of the most commonly-raised 
                objections to the notion of scalability solutions in general: most developers 
                praised ownership renunciation as the only clear way to maintain trustworthy system integrity.  
                For HoneyBadger to be 
                an effective and universally-applicable solution, it needs to 
                comprehensively and concretely address this 
                issue without making concessions in terms of capabilities or efficiency. 

                <br/><br/>

                Notably, the degree of desired administrator control varies based on 
                project objectives. Correspondingly, HoneyBadger offers 
                a variety of specialized implementations tailored to specific use cases. 
                 For instance, the HoneyBadgerDAO 
                model introduces full governance-based administration, constraining
                user-administrators to creating proposals for change, which must subsequently be 
                community-approved to  take action.  Direct permissions such as 
                read and write can only be provided to smart contracts, which are 
                encouraged to be open-sourced, allowing the community to personally 
                verify that malicious permissions are never introduced.  Conversely, 
                some projects have more relaxed needs, most often in the case that 
                they do not track native user assets (such as ERC20 tokens).  In this case, 
                more relaxed models, such as the default HoneyBadger system, are 
                completely adequate.  Overall, we leave it to developers to 
                determine the level of concrete integrity they need, and to their 
                community to determine whether they agree.  

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Extreme Optimization
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                While HoneyBadger will be available on all Solidity-based networks, it is 
                an Ethereum project at its core.  Consequently, gas costs are a major concern.  
                The system could offer the greatest conceivable features, but if that brings 
                about higher costs for users -- thereby reducing engagement -- then it is 
                of little ultimate value for Ethereum projects.  Hence, HoneyBadger uses 
                a unique <i>two-layered efficiency </i> approach, allowing developers to 
                bring costs significantly lower than even what is typically possible for 
                assembly-optimized single-contract systems.  

                <br/><br/>  

                The first layer of efficiency is <i>implementation.  </i>  The overarching 
                goal is to reduce the costs for each operation invoked at a user-level 
                to approach the conceivable minimal cost, thereby ensuring that future 
                competing systems can only produce marginal, if any improvements.  
                
                HoneyBadger 
                is almost entirely written in assembly, which significantly reduces 
                gas costs by removing extraneous safety checks introduced in the high-level-Solidity to 
                Yul (assembly) compilation step, which preceeds the Yul-to-bytecode compilation step.  
                Furthermore, we employ proprietary optimization techniques that greatly reduce 
                the need for intermediate calculations.  The result is highly-streamlined 
                functions that maintain security-parity with a high-level Solidity implementation. 
                You can explore gas costs directly within the <i>functions </i> tab.

                <br/><br/>

                The second layer is <i>logical efficiency.  </i> This layer is largely 
                centered around minimizing the frequency of expensive <i>sstore </i> 
                and <i>sload </i> operations.  A single <i>sstore </i> to a non-populated 
                slot costs 20,000 gas, while storing to a populated slot costs 5,000 gas.  
                For comparison, dividing two numbers costs only 5 gas.  
                
                HoneyBadger 
                makes it easy for developers to design their storage interface around 
                access and modification patterns.  By grouping values that are often 
                accessed or modified so that occupy the same storage slot, (HoneyBadger directly 
                manages the storage space) developers can utilize batch operations <i>[put/get]_batch </i>
                to modify or retrieve several values at the cost of a single <i>sstore</i> or <i>sload, </i> respectively.
                 

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Universal Applicability With Concrete Benefits
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                One of the driving goals behind the HoneyBadger system is to reduce 
                the debate regarding its particular relevance to a single question: 
                do we want more capabilities?  Given that security concerns are 
                the chief factor that discourages the use of scalability solutions, 
                HoneyBadger is well-positioned to enhance a massive subset of 
                the smart contract space.  In short, any smart contract system 
                that stores data is fully compatible.

                <br/><br/>

                Objectively, it is already clear why advanced smart contract systems 
                stand to derive considerable benefits from using the HoneyBadger system:
                complex projects are more prone to vulnerabilities and carry greater inherent 
                upgrade potential.  However, even basic applications become significantly 
                more capable when paired with state-of-the-art peripheral infrastructure.   
                Demands such as efficiency, vulnerability-resilience, and 
                amenability with respect to future innovation are widely-applicable, 
                and can be make-or-break factors down the line.  However, some contract systems 
                value rigidity or serve their full intended purpose up-front.  Even in this case, 
                high efficiency can lead to higher user engagement.  Overall, the wide variety of 
                benefits that HoneyBadger provides ensure that most projects stand to benefit from its use.  

            </BasicText>

            
            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Ease-of-Use
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                Our description of the HoneyBadger system suggests that it is extremely complex.  In fact, 
                this is true, but only in terms of its implementation.  From the perspective of a 
                developer utilizing the system in practice, it is extremely simple.  Configuration 
                can be completed with only a single function invocation, and using the system 
                is generally as simple as invoking basic <i>put </i> and <i>get </i> functions.  
                Furthermore, HoneyBadger is easy to integrate with existing code, and by 
                following best practices, developers can rapidly cultivate significant gas savings. 
                Additionally, full low-level control is available, though only 
                in select models, given the obvious security implications that this raises.  
                Developers can enable delegation support, allowing them to create custom logic 
                that directly manipulates HoneyBadger's storage space.  As a whole, 
                HoneyBadger is easy to use, but without abstracting away the possibility 
                for advanced custom applications when desired.  
                

            </BasicText>

            <BasicHeading className = {"inter" + " " + "fr" + " " + "t"} style = {{marginTop: "3vmax", fontWeight: "bold"}}>
                Accessibility and Community-Driven Growth
            </BasicHeading>

            <BasicText style = {{marginTop: "-1vmax", textAlign: "left"}} className = {"opensans" + " " + "t"}>

                HoneyBadger utilizes a unique NFT-based licensing model that ensures un-interrupted 
                availability and asset integrity, while incentivizing blockchain enthusiasts to get involved 
                and spread the word about the system.  Licenses come in two variants: single-deployment 
                and distributor-tier.  

                <br/><br/>

                As the name suggests, single-deployment licenses allow holders to 
                deploy a single instance of the system.  They can be traded freely 
                to other users in our marketplace, and can also be upgraded to 
                support "long" model variants, which have unlimited partitioned storage spaces.  
                Every time a license is minted, the market cost increases marginally, 
                allowing holders to benefit from acquiring the system, even if they ultimately 
                choose not to deploy it.  Critically, single-deployment licenses are no longer 
                tradeable after they have been used.  

                <br/><br/>

                Distributor licenses are a fun and powerful asset that allow 
                holders to deploy 5 (non-tradeble) instances of the system, including 
                long variants.  Additionally, they grant a monthly 50% discount for 
                tradeable single-deployment licenses, which is halved each time it used within 
                a single month.  These single-deployment licenses are also pre-unlocked, supporting 
                long variants.   

                <br/><br/>

                The logic behind our licensing model is quite simple: by creating incentives for 
                blockchain enthusiasts to get involved and spread the word, we can greatly 
                expand our target audience through concretely-aligned interests and 
                more rapidly gain recognition in the space.  Additionally, we hope to empower Web3 enthusiasts with 
                pointed insight towards factors that predict enduring success in the space by 
                cultivating greater public awareness of the scalability dilemma.  

                <br/><br/>

                Single-deployment licenses cost $1,400 in ETH (price is manually updated and 
                will maintain loose parity due to market fluctuations) as a base price, with 
                long-variant support costing an additional $400.  Distributor licenses cost 
                $5,000 in ETH.

            </BasicText>

        </PageWrapper>
    )
}