import '../../css/marquee.css'
import '../../css/TextStyles.css'
import '../../css/App.css'
import { useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap/gsap-core'
import { SplitText } from 'gsap/SplitText';
import { MotionPathPlugin } from 'gsap/all';
import '../../css/TitleTextStyles.css'
import qm from '../../assets/questionMarks.png'
import fp from '../../assets/fointingPinger.png'

export default function TitleText(props)
{
    gsap.registerPlugin(SplitText)
    gsap.registerPlugin(MotionPathPlugin)

    const Split = useRef(null);
    const Split2 = useRef(null);
    const Split3 = useRef(null);

    const loaded = useRef(false);

    const TL = useRef(gsap.timeline({}));

    //Handle init
    useEffect(() => 
    {
        Split.current = new SplitText(".fr", { type: "chars" });
        Split2.current = new SplitText(".ab", {type: "words"});
        Split3.current = new SplitText(".DescriptionText", {type: "words"});

        if (Split.current) {
          TL.current.from(Split.current.chars, { x: 20, opacity: 0, duration: .75, stagger: 0.1, delay: .75 })
          .from(".ab", {y: 10, opacity: 0, duration: .75, delay: .5})
        }

        loaded.current = true;
    
      }, []);


      useEffect(() =>
      {
        if(loaded.current == false) return;
        if(Split.current === null) return;
        if(Split2.current === null) return;

        if(TL.current.isActive()) return;

        if(props.hovered == true)
        {
          gsap.killTweensOf(".ab");
          gsap.killTweensOf(".ab2");
          gsap.killTweensOf(".DescriptionText");

          gsap.to(".ab", {opacity: 0, position: "absolute", duration: 0})
          gsap.to(".DescriptionText", {opacity: 0, duration: 0})
          gsap.to(".ab2", {opacity: 1, duration: .5})

        }
        else
        {
            gsap.killTweensOf(".ab");
            gsap.killTweensOf(".ab2");
            gsap.killTweensOf(".DescriptionText");

            gsap.to(".ab2", {opacity: 0})
            gsap.to(".ab", {opacity: 1, position: "relative"})
            gsap.to(".DescriptionText", {opacity: 1}, "<")
        }

      }, [props.hovered])

      useEffect(() =>
      {
        if(props.phase == 0) return;

        phaseTransitionFromZero(TL);

      }, [props.phase])


    return(
      <>
        <div className = "TextContainer">
            <h2 className = {"PS" + " " + "fr" + " " + "HomeTitle"}>HONEYBADGER</h2>
            <p className = {"inter ab Tagline"}>
            <b>Hunting Web3's Toughest Predators:</b> Scalability, Security, and Gas Costs
            </p>
            <p className = {"titleText" + " " + "ab2 DenText"}>
            🖱️ SCROLL TO ENTER THE DEN
            </p>
        </div>
      </>
    )
}

const phaseTransitionFromZero = (TL) =>
{
  gsap.killTweensOf(".ab");
  gsap.killTweensOf(".ab2");
  gsap.killTweensOf(".fr");
  
  gsap.to(".ab2", {opacity: 0})
  TL.current.timeScale(120).reverse();
}