import { Line } from "@react-three/drei";
import spline from "./spline"
import * as THREE from 'three';
import { useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { WormholeBadger } from "./WormHoleBadger.js";

export default function Wormhole()
{
    const {camera, scene, size} = useThree();
    const mousePos = useRef({x: 0, y: 0});
    const objRef = useRef();

    const handleMouseMove = (event) => {
        const x = (event.clientX / size.width) * 2 - 1;  // Normalize to [-1, 1]
        const y = -(event.clientY / size.height) * 2 + 1; // Normalize and invert Y
        mousePos.current = { x, y };
    };

    useEffect(() => {
        // Add event listener for mouse movement
        window.addEventListener("mousemove", handleMouseMove);
        return () => window.removeEventListener("mousemove", handleMouseMove);
    }, [size]);

    const points = spline.getPoints(100);

    const tubeGeometry = new THREE.TubeGeometry(spline, 400, .65, 32, true);
    const tubeMaterial = new THREE.MeshStandardMaterial({color: "grey", side: THREE.DoubleSide, wireframe: true});

    useFrame((state, delta) =>
    {
        const adjustedTime = state.clock.getElapsedTime() * 250;
        const loopTime = 20 * 1000;
        const t = (adjustedTime % loopTime) / loopTime;
        const t2 = (((adjustedTime * 1 )+ 650) % loopTime) / loopTime;

        // Base position and lookAt from the tube path
        const pos = tubeGeometry.parameters.path.getPointAt(t);
        const pos2 = tubeGeometry.parameters.path.getPointAt(t2);

        const lookAt = tubeGeometry.parameters.path.getPointAt((t + 0.01) % 1);
        const lookAt2 = tubeGeometry.parameters.path.getPointAt((t2 + 0.01) % 1);

        // Get mouse offset values from the ref
        const mouseX = mousePos.current.x;
        const mouseY = mousePos.current.y;

        // Limit the influence of the mouse movement on the lookAt target
        const maxOffset = 0.1; // You can adjust this value to control how much the mouse influences the camera
        const offsetX = maxOffset * mouseX; // Apply a fraction of the mouse position to the lookAt
        const offsetY = maxOffset * mouseY;

        // Adjust the lookAt position based on mouse movement
        const modifiedLookAt = new THREE.Vector3(
            lookAt.x + offsetX,
            lookAt.y + offsetY,
            lookAt.z
        );

        // Move camera along the path and apply the modified lookAt
        camera.position.copy(pos);
        camera.lookAt(modifiedLookAt);

        objRef.current.position.x = pos2.x;
        objRef.current.position.y = pos2.y;
        objRef.current.position.z = pos2.z;

        if(lookAt2) lookAt2.x *= -Math.PI / 2;
        objRef.current.lookAt(lookAt2)
    })

    return(
        <>

            <ambientLight />
            <fog attach="fog" color="#262a33" near={0} far={4} />
            <mesh 
                material={tubeMaterial}
                geometry={tubeGeometry}
            />

            <WormholeBadger ref = {objRef}/>


        </>
    )
}