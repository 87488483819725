import {  MeshBasicMaterial } from "three";
import {  useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { HBPhase1MatBody } from "./HBPhase1Material";
import React, { useEffect, useRef, useMemo } from "react";
import { useStoryBrandSectionContext } from "../../LowerScrollSection/StoryBrandSection/StoryBrandSectionWrapper";

export default function HBPhase1(props)
{
    const { nodes } = useGLTF("/hb.glb");
    const {shinyBadgerRef} = useStoryBrandSectionContext();

    const r = useRef()
    const r2 = useRef()
    const r3 = useRef()
    const ref = useRef();

    const eyes = useMemo(() =>
    {
        return new MeshBasicMaterial({color: "black", transparent: true, opacity: 0});
    })

    const shaderMatBody = useMemo(() =>
    {
        return HBPhase1MatBody();
    }, [])


    useEffect(() =>
    {
        if(!r.current || !r2.current) return;

        r.current.material.uniforms.u_hovered.value = 1;
        r2.current.material.uniforms.u_hovered.value = 1  

    }, [])

    useFrame((_, delta) =>
    {
        if(!r.current || !r2.current) return;

        r.current.material.uniforms.time.value = 
            r.current.material.uniforms.time.value + delta * .25;
        r2.current.material.uniforms.time.value = 
            r2.current.material.uniforms.time.value + delta * .25;
    })

    return (
        <group ref={shinyBadgerRef}  dispose={null} {...props}>
            <mesh
                ref = {r}
                castShadow
                receiveShadow
                geometry={nodes.HoneyBadger_1.geometry}
                material={shaderMatBody}>
            </mesh>

            <mesh
                ref = {r2}
                castShadow
                receiveShadow
                geometry={nodes.HoneyBadger_2.geometry}
                material={shaderMatBody}
            />
            <mesh
                ref = {r3}
                castShadow
                receiveShadow
                geometry={nodes.HoneyBadger_3.geometry}
                material={eyes}
            />
        </group>
    );
};
