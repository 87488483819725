import React, { createContext, useContext, useRef } from 'react';

// Create the context outside of the component
const StoryBrandSectionContext = createContext();

// Custom hook to access the context
export const useStoryBrandSectionContext = () => useContext(StoryBrandSectionContext);

export default function StoryBrandSectionWrapper({ children }) {
    const neoGlassesRef = useRef();
    const shinyBadgerRef = useRef();

    return (
        <StoryBrandSectionContext.Provider value={{ neoGlassesRef, shinyBadgerRef }}>
            {children} 
        </StoryBrandSectionContext.Provider>
    );
}