import "./index.css";

import React, {useState} from "react";
import Scene from "./components/Scene/Scene";
import TitleText from "./components/Text/TitleText";

export default function QuestSystem(props)
{
    const PAGE_MAX = 2;

    const [HoneyBadgerHovered, setHoneyBadgerHovered] = useState(false);
    const [phase, setPhase] = useState(0);
    const [swapTime, setSwapTime] = useState(99999999999999);
    const [page, setPage] = useState(0);
    const [hasPageChanged, setPageChanged] = useState(false);
    const [dimsChanged, setDimsChanged] = useState(false);
    const [hasTransitioned, setTransitioned] = useState(false);
    const [questSection, setQuestSection] = useState(null);
    const [group, setGroup] = useState(null);
    const [denPhase, setDenPhase] = useState(0);
    const [denTransition, setDenTransition] = useState(true);
    const [test, setTest] = useState(false);
    const [removeShiny, setRemoveShiny] = useState(false)

  /*
          <ChooseGroup 
            phase={phase} 
            group={group} 
            setGroup={(v) => setGroup(v)} 
          />

  */

    return(
        <>

          <TitleText 
            hovered={HoneyBadgerHovered} 
            phase={phase} 
            dimsChanged={dimsChanged} 
          />

          <Scene
            removeShiny = {removeShiny}
            setRemoveShiny = {(v) => setRemoveShiny(v)}
            test={test}
            group={group}
            hasTransitioned={hasTransitioned}
            setTransitioned={(v) => setTransitioned(v)}
            hasPageChanged={hasPageChanged}
            setPageChanged={(v) => setPageChanged(v)}
            questSection={questSection}
            setQuestSection={(v) => setQuestSection(v)}
            page={page}
            pageMax={PAGE_MAX}
            dimsChanged={dimsChanged}
            setPage={(v) => setPage(v)}
            phase={phase}
            setPhase = {(v) => setPhase(v)}
            swapTime={swapTime}
            setSwapTime={(v) => setSwapTime(v)}
            style={{ ZIndex: -1, position: "absolute", overflowX: "hidden" }}
            hovered={HoneyBadgerHovered}
            setHovered={(v) => setHoneyBadgerHovered(v)}
            denTransition={denTransition}
            setDenTransition={(v) => setDenTransition(v)}
            setDenPhase={(v) => setDenPhase(v)}
          />
        </>
    )
}